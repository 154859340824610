import { isEmpty } from "lodash";
import moment from "moment";
import {
  SURGERY_CASE_ATTR,
  ASCII_ENCODE,
  caseFilterType,
  newCaseState
} from "./consts";
import {
  DATE_FORMAT,
  SHORT_DATE_FORMAT,
  parseDateFormat,
  getFormatDateFromStringDate
} from "./datesHelper";
import { getCasesEndpoint } from "./URLHelper";
import { checkValue } from "./common";

export const evenRound = (numberToRound, decimalPlaces = 0) => {
  const m = Math.pow(10, decimalPlaces);
  const n = +(decimalPlaces ? numberToRound * m : numberToRound).toFixed(8);
  const i = Math.floor(n),
    f = n - i;
  const e = 1e-8;
  const isEvenResult = i % 2 == 0 ? i : i + 1;
  const r = f > 0.5 - e && f < 0.5 + e ? isEvenResult : Math.round(n);
  return decimalPlaces ? r / m : r;
};

export const isEven = numberToCheck => {
  if (isNaN(numberToCheck)) {
    return null;
  }
  return 0 === numberToCheck % 2;
};

export const bankersRound = numberToRound => {
  if (isNaN(numberToRound)) {
    return null;
  }
  const r = Math.round(numberToRound);
  const numberToRoundSign = numberToRound > 0 ? numberToRound : -numberToRound;

  if (numberToRoundSign % 1 === 0.5) {
    if (isEven(r)) {
      return r;
    }
    return r - 1;
  }
  return r;
};

export const getQualityTag = qualityValue => {
  const qualityTags = {
    H: "High quality",
    M: "Medium quality",
    L: "Low quality",
    NS: "Not supported"
  };

  return qualityTags[qualityValue];
};

export const filterCases = (
  filters,
  getCaseList,
  activeFilters,
  setFiltersCleared
) => {
  let filter;
  let requestParams = "";

  for (filter in filters) {
    let selectedFilter = filters[filter];
    if (!isEmpty(selectedFilter)) {
      let filterBy = filter;

      switch (filter) {
        case SURGERY_CASE_ATTR.CASE_STATUS:
          ({ selectedFilter, filterBy } = getCaseStatusFilter(selectedFilter));
          break;
        case SURGERY_CASE_ATTR.SURGERY_STATUS:
          ({ selectedFilter, filterBy } = getSurgeryStatusFilter());
          break;
        case SURGERY_CASE_ATTR.SURGERY_DATE:
          ({ selectedFilter, filterBy } = getSurgeryDateFilter(selectedFilter));
          break;
        case SURGERY_CASE_ATTR.LAST_MODIFIED:
          ({ selectedFilter, filterBy } =
            getLastModifiedDateFilter(selectedFilter));
          break;
        case SURGERY_CASE_ATTR.MODALITY:
          ({ selectedFilter, filterBy } = getModalityFilter(selectedFilter));
          break;
        case SURGERY_CASE_ATTR.PATIENT_NAME:
        case SURGERY_CASE_ATTR.MRN:
        case SURGERY_CASE_ATTR.CLASSIFICATION:
        case SURGERY_CASE_ATTR.CASE_NUMBER:
          ({ selectedFilter, filterBy } = getTextFilter(
            selectedFilter,
            filter
          ));
          break;
        default:
          break;
      }
      requestParams = requestParams.concat(`&${filterBy}=${selectedFilter}`);
    }
  }

  setFiltersCleared(isEmpty(filters));

  activeFilters.current = requestParams;

  getCaseList(`${getCasesEndpoint()}${requestParams}`);
};

const getTextFilter = (selectedFilter, filter) => {
  return {
    selectedFilter: `${ASCII_ENCODE.PERCENT}${encodeURIComponent(
      selectedFilter
    )}${ASCII_ENCODE.PERCENT}`,
    filterBy: caseFilterType[filter]
  };
};

const getCaseStatusFilter = selectedFilter => {
  const filterBy = "status";
  return { selectedFilter: selectedFilter.join(ASCII_ENCODE.PIPE), filterBy };
};

const getSurgeryStatusFilter = () => {
  const selectedFilter = [newCaseState.PLANNED, newCaseState.UNPLANNED];
  const filterBy = `${SURGERY_CASE_ATTR.SURGERY_STATUS}`;
  return { selectedFilter: selectedFilter.join(ASCII_ENCODE.PIPE), filterBy };
};

const getModalityFilter = selectedFilter => {
  const filterBy = `${SURGERY_CASE_ATTR.MODALITY}`;
  return { selectedFilter: selectedFilter.join(ASCII_ENCODE.PIPE), filterBy };
};

const getLastModifiedDateFilter = selectedFilter => {
  const filteredDates = selectedFilter[0].split("-");
  const firstDate = moment(filteredDates[0])
    .set({ hour: 0, minute: 0, second: 0 })
    .format("YYYY-MM-DDThh:mm:ss");
  const secondDate = moment(filteredDates[1])
    .set({ hour: 0, minute: 0, second: 0 })
    .format("YYYY-MM-DDThh:mm:ss");

  return {
    selectedFilter: `${firstDate}Z~${secondDate}Z`,
    filterBy: "surgeryPlannings.lastModifiedPlanningDate"
  };
};

const getSurgeryDateFilter = selectedFilter => {
  const filteredDates = selectedFilter[0].split("-");
  const firstDate = moment(filteredDates[0], DATE_FORMAT.ISO).format(
    DATE_FORMAT.bigEndian
  );
  const secondDate = moment(filteredDates[1], DATE_FORMAT.ISO).format(
    DATE_FORMAT.bigEndian
  );
  return {
    selectedFilter: `${firstDate}~${secondDate}`,
    filterBy: SURGERY_CASE_ATTR.SURGERY_DATE
  };
};

export const parseInputData = caseList => {
  const parsedData = [];
  caseList.forEach((currentCase, index) => {
    const surgeryDate =
      currentCase?.date && moment(currentCase.date).format(SHORT_DATE_FORMAT);

    const parsedCase = {
      key: index,
      [SURGERY_CASE_ATTR.ID]: checkValue(currentCase?.id),
      [SURGERY_CASE_ATTR.PATIENT_NAME]: checkValue(
        currentCase?.patient.fullName
      ),
      [SURGERY_CASE_ATTR.MODALITY]: checkValue(currentCase?.modality),
      [SURGERY_CASE_ATTR.MRN]: checkValue(currentCase?.medicalRecordNumber),
      [SURGERY_CASE_ATTR.SURGERY_DATE]: checkValue(
        parseDateFormat(surgeryDate)
      ),
      [SURGERY_CASE_ATTR.SURGERY_STATUS]: checkValue(
        newCaseState[currentCase?.state]
      ),
      [SURGERY_CASE_ATTR.CLASSIFICATION]: currentCase?.classification || "",
      [SURGERY_CASE_ATTR.LAST_MODIFIED]: checkValue(
        getFormatDateFromStringDate(
          currentCase?.adaptedLastModifiedPlanningDate
        )
      ),
      [SURGERY_CASE_ATTR.CASE_NUMBER]: currentCase?.caseNumber,
      [SURGERY_CASE_ATTR.SURGEON_ID]: checkValue(currentCase?.userId),
      [SURGERY_CASE_ATTR.SURGEON_NAME]: checkValue(currentCase?.surgeonName),
      [SURGERY_CASE_ATTR.ORIGINAL_CASE_NUMBER]: checkValue(
        currentCase?.originalCaseNumber
      ),
      [SURGERY_CASE_ATTR.CASE_STATUS]: checkValue(currentCase?.status),
      [SURGERY_CASE_ATTR.IS_DECLINED]: checkValue(currentCase?.isDeclined),
      [SURGERY_CASE_ATTR.IS_SYNCED]:
        !isEmpty(currentCase?.surgeryCaseFiles.references) &&
        ["UPLOADED_APPLICATION", "UPLOADED_MANUAL"].includes(
          currentCase?.surgeryCaseFiles.references[0]?.state
        )
    };
    parsedData.push(parsedCase);
  });
  return parsedData;
};

export const filterByList = (value, record) => {
  if (newCaseState.DECLINED === value && record.isDeclined) {
    return true;
  }
  if (!record.isDeclined) {
    return (
      record.state.indexOf(value) === 0 || record.state.indexOf(value) === 1
    );
  }
};

export const filterByText = (value, record, filter) => {
  return record[filter].toString().toLowerCase().includes(value.toLowerCase());
};

export const copyToClipboard = async (value, name, message) => {
  try {
    await navigator.clipboard.writeText(value);
    message.success({
      content: `${name} copied`,
      key: "copyValue",
      duration: 2
    });
  } catch (error) {
    return null;
  }
};
