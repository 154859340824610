import { dateToStringFormat } from "./datesHelper";

export const appMockedStore = {
  auth: {
    isLogin: true,
    isLoading: { metadata: false, segmentation: false },
    isDownloading: { update: false },
    refresh_token_expires_in: new Date() + 1,
    userId: "abc123"
  },
  dicomFiles: { "path/to/file": { error: "error", file: "file" } },
  selectedDicom: {
    selectedFile: null,
    selectedPatient: null,
    selectedStudy: null,
    selectedSerie: null
  },
  segmentation: {
    "76a767fd-757e-43d3-94e2-a1736c972d96": {
      id: "76a767fd-757e-43d3-94e2-a1736c972d96",
      created: "2019-11-05T09:21:25.738240",
      status: "FINISHED",
      input: {
        path: "C:\\Users\\User-PC\\Documents\\DICOMDIR",
        patient: "3",
        study: "9999.82118032130525556189739282678451192521",
        series: "9999.292578481311132748547228841723593987421"
      },
      patientData: {
        selectedPatientData: {
          name: "Test name"
        },
        selectedSerieData: {
          number: 10,
          description: "Series description"
        }
      },
      savingCaseStatus: "SUCCESS"
    }
  },
  sidebarHighlightedItem: { currentLocation: "/" },
  caseList: {
    plannedCasesYears: [],
    casesByYear: [],
    status: null,
    selectedFilter: "",
    selectedDateFilter: null,
    cases: {},
    casesStateCount: {
      planned: 32,
      unplanned: 773,
      surgeryDay: 1,
      postOp: 199
    },
    caseStateCountError: false,
    casesByDayPerWeek: [
      {
        key: dateToStringFormat(new Date().setDate(new Date().getDate() - 17)),
        count: 1
      },
      {
        key: dateToStringFormat(new Date().setDate(new Date().getDate() - 16)),
        count: 1
      },
      {
        key: dateToStringFormat(new Date().setDate(new Date().getDate() - 15)),
        count: 1
      },
      {
        key: dateToStringFormat(new Date().setDate(new Date().getDate() - 14)),
        count: 1
      },
      {
        key: dateToStringFormat(new Date().setDate(new Date().getDate() - 13)),
        count: 1
      },
      {
        key: dateToStringFormat(new Date().setDate(new Date().getDate() - 12)),
        count: 1
      },
      {
        key: dateToStringFormat(new Date().setDate(new Date().getDate() - 11)),
        count: 1
      },
      {
        key: dateToStringFormat(new Date().setDate(new Date().getDate() - 10)),
        count: 1
      },
      {
        key: dateToStringFormat(new Date().setDate(new Date().getDate() - 9)),
        count: 1
      },
      {
        key: dateToStringFormat(new Date().setDate(new Date().getDate() - 8)),
        count: 1
      },
      {
        key: dateToStringFormat(new Date().setDate(new Date().getDate() - 7)),
        count: 1
      },
      {
        key: dateToStringFormat(new Date().setDate(new Date().getDate() - 6)),
        count: 1
      },
      {
        key: dateToStringFormat(new Date().setDate(new Date().getDate() - 5)),
        count: 1
      },
      {
        key: dateToStringFormat(new Date().setDate(new Date().getDate() - 4)),
        count: 1
      },
      {
        key: dateToStringFormat(new Date().setDate(new Date().getDate() - 3)),
        count: 1
      },
      {
        key: dateToStringFormat(new Date().setDate(new Date().getDate() - 2)),
        count: 1
      },
      {
        key: dateToStringFormat(new Date().setDate(new Date().getDate() - 1)),
        count: 1
      }
    ],
    casesByDayPerWeekStatus: "SUCCESS",
    caseListPopOverStatus: null,
    caseListPopOver: []
  },
  surgeryCase: { casePlanningStatus: null },
  applicationSegmentationUpload: { casesStatus: {} },
  archiveCase: { archiveCasesStatus: {} },
  requestSupport: {
    dicomUploadTasks: {
      [123]: {
        status: "LOADING",
        progress: 80,
        patientName: "Patient Name",
        error: "error"
      }
    }
  }
};

export const DicomDirectoryTreeMockedStore = {
  auth: {
    isLogin: true
  },
  dicomFiles: {
    "/Users/nicoramirez/Documents/GSI RESOURCES/CT_Disc2_20190912": {
      0: {
        name: "047 PAT",
        id: "074489",
        birthdate: "1946-04-14",
        gender: "U",
        comments: "",
        _id: 0,
        Studies: {
          study1: {
            id: "",
            accessionNumber: "3081188614376110",
            studyDate: "2017-11-05",
            studyTime: "",
            perfPhysicianName: "",
            description: "CT SHOULDER",
            _id: "9999.57280495732235825128012555912456223327",
            Series: {
              serie1_1: {
                number: 205,
                modality: "NOT CT",
                isOrientationSupported: true,
                laterality: "",
                contrastAgent: "",
                description: "SHD SCAP LT OMAR, iDose (1)",
                relativePath: "STU00007",
                imageType: ["ORIGINAL", "PRIMARY", "AXIAL"],
                _id: "9999.111499149925233405398171142488513858195"
              }
            }
          }
        }
      },
      1: {
        name: "043 PAT",
        id: "239220",
        birthdate: "1942-05-09",
        gender: "U",
        comments: "",
        _id: 1,
        Studies: {
          study2: {
            id: "",
            accessionNumber: "1951107529401425",
            studyDate: "2012-06-30",
            studyTime: "",
            perfPhysicianName: "",
            description: "CT UPPER EXT RIGHT W/O CONTRAST",
            _id: "9999.112541188771412707937176613721438309944",
            Series: {
              serie2_1: {
                number: 2,
                modality: "CT",
                isOrientationSupported: true,
                laterality: "",
                contrastAgent: "",
                description: "Blueprint axial bone window",
                relativePath: "STU00000",
                imageType: ["DERIVED", "PRIMARY", "AXIAL", "CT_SOM5 SPI"],
                _id: "9999.31809046861734176831944160742686400024"
              }
            }
          }
        }
      }
    }
  },
  selectedDicom: {
    selectedFile:
      "/Users/nicoramirez/Documents/GSI RESOURCES/CT_Disc2_20190912",
    selectedPatient: "0",
    selectedStudy: "9999.57280495732235825128012555912456223327",
    selectedSerie: "9999.155498161976432559456761066610303757347"
  },
  segmentation: {},
  surgeryCase: {},
  sidebarHighlightedItem: {
    currentLocation: "#/"
  },
  caseList: {}
};

export const userPreferencesMockedStore = {
  userPreferences: {
    preferences: {
      planning: {
        glenosphere: false,
        movementControl: true,
        planes: false,
        zoom: 2,
        implant: true,
        scapula: true,
        coordinateAxes: false,
        centralScrew: false,
        rotationControl: true,
        humerus: false,
        cameraView: "Front",
        opacity: 2,
        backsideSupport: false,
        pocket: false
      },
      measurements: {
        humerus: false,
        cameraView: "Front",
        planes: true,
        zoom: 3,
        opacity: 1,
        scapula: true,
        coordinateAxes: true
      }
    }
  }
};

export const mockedProtocolValues = {
  protocolValues: {
    protocol_metadata: {
      sliceThickness: {
        value: 123,
        qualityTag: "H"
      },
      amountOfSlices: {
        value: 200,
        qualityTag: "NS"
      },
      imageSize: {},
      pixelSpacing: {},
      fileOfView: {},
      patientPosition: {},
      patientOrientation: {
        x: 12,
        y: 11.5
      },
      transferSyntaxUID: {},
      exposureTime: {},
      convolutionKernel: {},
      kvp: {}
    }
  }
};

export const mockedSurgeryMeasurements = {
  segmentationData: {
    sphereRadius: 0.2583514153957367,
    boundingBox: {
      LeftFrontTop: {
        x: 0.985986328125,
        y: -1.519091796875,
        z: 0.8970091247558594
      },
      RightFrontBottom: {
        x: -1.6536621093750001,
        y: -1.519091796875,
        z: -0.7710078430175782
      },
      LeftBackTop: {
        x: 0.985986328125,
        y: 1.120556640625,
        z: 0.8970091247558594
      },
      LeftBackBottom: {
        x: 0.985986328125,
        y: 1.120556640625,
        z: -0.7710078430175782
      },
      LeftFrontBottom: {
        x: 0.985986328125,
        y: -1.519091796875,
        z: -0.7710078430175782
      },
      RightBackBottom: {
        x: -1.6536621093750001,
        y: 1.120556640625,
        z: -0.7710078430175782
      },
      RightBackTop: {
        x: -1.6536621093750001,
        y: 1.120556640625,
        z: 0.8970091247558594
      },
      RightFrontTop: {
        x: -1.6536621093750001,
        y: -1.519091796875,
        z: 0.8970091247558594
      }
    },
    glenoidCenter: {
      x: 0.18688851806667398,
      y: -0.37458121990832016,
      z: 0.31515739562756395
    },
    protocolMetadata: {
      exposureTime: { expected: "1000 - 1500", qualityTag: "NC", value: 1000 },
      kvp: { expected: "120 - 140", qualityTag: "NC", value: 140 },
      fileOfView: { expected: "1 - 270", qualityTag: "H", value: 265 },
      pixelSpacing: { expected: ".51", qualityTag: "H", value: 0.517578125 },
      xRayTubeCurrent: { expected: 240, qualityTag: "NC", value: 240 },
      sliceThickness: { expected: ".625 - 1.250", qualityTag: "H", value: 1 },
      patientPosition: { expected: "HFS", qualityTag: "NC", value: "HFS" },
      convolutionKernel: {
        expected: "Bone or Bone+",
        qualityTag: "NC",
        value: "B60s"
      },
      hounsfieldValues: {
        min: -1024,
        max: 3072,
        expected: "-1024 - 3071",
        qualityTag: "NC"
      },
      imageSize: {
        expected: "512x512 or 768x768",
        qualityTag: "H",
        value: "512x512"
      },
      patientOrientation: {
        qualityTag: "H",
        x: 0,
        y: 0,
        expected_X: "0° - 30",
        expected_Y: "0° - 30"
      },
      amountOfSlices: { expected: "100 - 500", qualityTag: "H", value: 279 },
      transferSyntaxUID: {
        expected: "",
        qualityTag: "H",
        value: "1.2.840.10008.1.2.4.70"
      }
    },
    patientId: "0",
    inferiorTip: {
      x: -0.33642578125,
      y: 0.771191418170929,
      z: -0.5494959354400635
    },
    sphereCenter: {
      x: 0.3868641257286072,
      y: -0.6104891300201416,
      z: 0.3165624737739563
    },
    trigonum: {
      x: -0.500659167766571,
      y: 0.3519531190395355,
      z: 0.5130051970481873
    },
    studyId: "9999.248664481922352704255983434827155937602",
    bestFitSphereCenter: {
      x: 0.3725557109191925,
      y: -0.5650815337540276,
      z: 0.3251309671720135
    },
    seriesId: "9999.142248873137007682910093591924822751062"
  },
  patientProfileData: {
    HumerusSubluxation: 0.5003791451454163,
    GlenoidInclination: 6,
    GlenoidVersion: -11
  },
  lastUpdateDate: "2022-04-05T18:21:14.877637",
  action: null,
  surgeonData: {
    GlenoidCenter: {
      x: 0.18688847124576569,
      y: -0.37458157539367676,
      z: 0.31515729427337646
    },
    Trigonum: {
      x: -0.5006592273712158,
      y: 0.35195308923721313,
      z: 0.5130051374435425
    },
    BestFitSphereCenter: {
      x: 0.3968724012374878,
      y: -0.53846675157547,
      z: 0.3118988275527954
    },
    InferiorTip: {
      x: -0.3364260494709015,
      y: 0.7711915969848633,
      z: -0.5494961142539978
    }
  },
  id: 123456,
  state: "CORRECTED",
  type: "surgerymeasurements"
};

const mockedImplantData = {
  SuperiorDepth: 0.0002928839239757508,
  SecondaryVersion: 0,
  RimPartialSeating: 0.1875,
  PosteriorDepth: -0.10000000149011612,
  PositionOffset: {
    x: 0,
    y: 0,
    z: 0
  },
  SecondaryInclination: 0,
  InferiorDepth: 0.004136544186621904,
  Rotation: 0,
  Inclination: 0,
  Lateralization: -5.543552816789088e-8,
  Version: 0,
  Seating: 0.4874371886253357,
  RimFullSeating: 0.21875,
  AnatomicOptsData: {
    ImplantType: 0,
    Depth: 0,
    Diameter: 2
  },
  AnteriorDepth: -0.10000000149011612,
  ReverseOptsData: {
    GlenosphereType: 0,
    CentralScrew: 0,
    GlenosphereDiameter: 0,
    ImplantModel: 0,
    BaseplateDiameter: 0,
    BaseplateStem: 0,
    BaseplateType: 0,
    GlenosphereOffset: 1,
    BaseplateAngle: 0,
    GlenosphereRotation: 0
  }
};

const mockedCTScansSettingsData = {
  SelectedGradient: 0,
  OneHLStop1: 0.31103515625,
  TwoHLStop2: 0.4111328125,
  ImplantOpacity: 0.5,
  Brightness: 0,
  TwoHLStop3: 0.73828125,
  OneHLStop2: 0.4111328125,
  IsUsingColorBlindedFriendlyGradients: false,
  TwoHLStop1: 0.31103515625,
  Contrast: 0
};

export const planningMockedData = [
  {
    data: {
      PlanData: {
        name: "Plan 1",
        idx: 0,
        isFavorite: false
      },
      ImplantData: { ...mockedImplantData },
      apiVersion: "1.1",
      CustomMeasurements: [],
      CustomMeasurePoints: [],
      ImplantName: "0S0CI24006___Glenoid_Circular_In_Line_Peg_24mm_x_6mm_RevA",
      CTScanSettingsData: { ...mockedCTScansSettingsData },
      Procedure: 0
    },
    lastUpdateDate: "2022-04-07T14:22:54.286464",
    lastModifiedPlanningDate: "2022-04-07T14:22:54.283292Z",
    action: null,
    id: 123456,
    type: "surgeryplanning"
  },
  {
    data: {
      PlanData: {
        name: "Plan 2",
        idx: 1,
        isFavorite: false
      },
      ImplantData: { ...mockedImplantData },
      apiVersion: "1.1",
      CustomMeasurements: [],
      CustomMeasurePoints: [],
      ImplantName: "0S0CI24006___Glenoid_Circular_In_Line_Peg_24mm_x_6mm_RevA",
      CTScanSettingsData: { ...mockedCTScansSettingsData },
      Procedure: 0
    },
    lastUpdateDate: "2022-04-07T14:22:54.541279",
    lastModifiedPlanningDate: "2022-04-07T14:22:54.541035Z",
    action: null,
    id: 123456,
    type: "surgeryplanning"
  }
];

export const TEST_TOKEN =
  "eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJzUGtfY1BOejV6Z0c4Tk1ibEg3R19MSFprQm9Ja0haSTc2SGtJZkxRUVh3In0.eyJleHAiOjE2MzE3OTM3MjAsImlhdCI6MTYzMTc5MzY2MCwiYXV0aF90aW1lIjoxNjMxNzkzNjU5LCJqdGkiOiJlYWQ3MTkwYS01YWJmLTQzNjQtYTU0Yy0yNGYzYzg3OWQ0MWQiLCJpc3MiOiJodHRwczovL3Nzby5kZXYuZ2VuZXNpc3BsYW5uaW5nc29mdHdhcmUuY29tL2F1dGgvcmVhbG1zL21hc3RlciIsImF1ZCI6WyJtYXN0ZXItcmVhbG0iLCJhY2NvdW50Il0sInN1YiI6IjZlNmM1MmZmLTY5ZjMtNGJmYy04N2M0LTU0YzBhMzMxMzM2MyIsInR5cCI6IkJlYXJlciIsImF6cCI6InNpLWJhY2tvZmZpY2UtdGVzdCIsInNlc3Npb25fc3RhdGUiOiI1MDU5ODM3Mi0wYjhlLTQ0NWEtODkxNy0yZTUzMzA2MmU4ODkiLCJhY3IiOiIxIiwiYWxsb3dlZC1vcmlnaW5zIjpbIioiXSwicmVhbG1fYWNjZXNzIjp7InJvbGVzIjpbImNyZWF0ZS1yZWFsbSIsImRldiIsIm9mZmxpbmVfYWNjZXNzIiwic3VyZ2VvbiIsImFkbWluIiwidW1hX2F1dGhvcml6YXRpb24iXX0sInJlc291cmNlX2FjY2VzcyI6eyJtYXN0ZXItcmVhbG0iOnsicm9sZXMiOlsidmlldy1pZGVudGl0eS1wcm92aWRlcnMiLCJ2aWV3LXJlYWxtIiwibWFuYWdlLWlkZW50aXR5LXByb3ZpZGVycyIsImltcGVyc29uYXRpb24iLCJjcmVhdGUtY2xpZW50IiwibWFuYWdlLXVzZXJzIiwicXVlcnktcmVhbG1zIiwidmlldy1hdXRob3JpemF0aW9uIiwicXVlcnktY2xpZW50cyIsInF1ZXJ5LXVzZXJzIiwibWFuYWdlLWV2ZW50cyIsIm1hbmFnZS1yZWFsbSIsInZpZXctZXZlbnRzIiwidmlldy11c2VycyIsInZpZXctY2xpZW50cyIsIm1hbmFnZS1hdXRob3JpemF0aW9uIiwibWFuYWdlLWNsaWVudHMiLCJxdWVyeS1ncm91cHMiXX0sImFjY291bnQiOnsicm9sZXMiOlsibWFuYWdlLWFjY291bnQiLCJtYW5hZ2UtYWNjb3VudC1saW5rcyIsInZpZXctcHJvZmlsZSJdfX0sInNjb3BlIjoicHJvZmlsZSBlbWFpbCIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJuYW1lIjoiTHVjaWFubyBCZXJ0ZWxsbyIsInByZWZlcnJlZF91c2VybmFtZSI6ImxiZXJ0ZWxsbyIsImdpdmVuX25hbWUiOiJMdWNpYW5vIiwiZmFtaWx5X25hbWUiOiJCZXJ0ZWxsbyIsImVtYWlsIjoibGJlcnRlbGxvQHNpbXRsaXguY29tIn0.kxv05ufWSGHjC78_cHacHLcXE0iATg5fuiY8Uj0pzA4aRGTgY58HQo-Wf6QYmfMD7C1H5gNq-LuwFqUMjLCRsD6PIEszT-Srdd6LPjXhWlC__9gPiMTKVUV9pi-ZL8ELpIR6GaXtln_YcvYgaFZPfeaIolYrgGzOkxzrpuPXS1I6z0XYiYcwnM16OV34dC5ERGbqGPkXS-SmwmIDzYwMTuHZr7CjMFbl63d7ZZjDAVWMLMS2sKFcBWoXep5mDSfGK7wiRnadoy9PahwgEmOifr_5wruuNm69BgwXrQX59FqjQrJuW8zpkfKs7oQWscqTI2epgKoUU5XEuPlIZtw7yw";

export const mockedEnvVariables = {
  REACT_APP_KEYCLOAK_URL: "https://sso.dev.genesisplanningsoftware.com/auth/",
  REACT_APP_KEYCLOAK_REALM: "master",
  REACT_APP_PORTAL_CLIENT_ID: "gsi-portal-shell",
  REACT_APP_PORTAL_URL: "https://si-portal.dev.genesisplanningsoftware.com/",
  REACT_APP_SSO_AUTHENTICATOR_URL: "http://localhost:9001/auth",
  REACT_APP_SURGERY_CORE_URL:
    "https://surgery-core.dev.genesisplanningsoftware.com",
  REACT_APP_JENKINS_URL: "https://tools.ci.genesisplanningsoftware.com/jenkins",
  REACT_APP_NOTIFICATION_EMITTER_URL:
    "https://notification-emitter.si.genesisplanningsoftware.com/secured/preview",
  REACT_APP_SUPPORT_JOB_ENDPOINT: "job/Preview-Shoulder-Support_Dev/job",
  REACT_APP_KEYCLOAK_CLIENT_ID: "gsi-portal-shell",
  REACT_APP_SURGERY_ADJACENT_URL:
    "https://surgery-adjacent.si.genesisplanningsoftware.com",
  REACT_APP_PASSWORD_RESET_TIMEOUT: 86400
};

export const mockedImplantsInfo = {
  scapular_anatomic_configs: {
    implant_insets: [
      {
        id: 0,
        displayName: "Inset 0°",
        displayOrder: 0,
        angleValue: 0
      },
      {
        id: 1,
        displayName: "Inset 5°",
        displayOrder: 1,
        angleValue: 5
      },
      {
        id: 2,
        displayName: "Inset 10°",
        displayOrder: 2,
        angleValue: 10
      }
    ],
    implant_diameters: [
      {
        id: 0,
        displayName: "20mm",
        displayOrder: 0,
        diameterValue: 20
      },
      {
        id: 1,
        displayName: "22mm",
        displayOrder: 1,
        diameterValue: 22
      },
      {
        id: 2,
        displayName: "24mm",
        displayOrder: 2,
        diameterValue: 24
      },
      {
        id: 3,
        displayName: "27mm",
        displayOrder: 3,
        diameterValue: 27
      },
      {
        id: 4,
        displayName: "30mm",
        displayOrder: 4,
        diameterValue: 30
      }
    ],
    implant_thicknesses: [
      {
        id: 0,
        displayName: "6mm",
        displayOrder: 0,
        thicknessValue: 6
      },
      {
        id: 1,
        displayName: "8mm",
        displayOrder: 1,
        thicknessValue: 8
      }
    ]
  },
  anatomic: [
    {
      id: "0S0CI20006_Rev_A",
      name: "Inset 0° 20mmx6mm",
      shortName: "Inset 0",
      sizeLabel: "20mmx6mm",
      partNumber: "0S0CI20006",
      revision: "RevA",
      commercial_status: 0,
      inset: "0°",
      insetCode: 0,
      depth: "6mm",
      depthCode: 0,
      diameter: "20mm",
      diameterCode: 0
    },
    {
      id: "2N2AI20005_Rev_A",
      name: "Inset 5° 20mm",
      shortName: "Inset 5",
      sizeLabel: "20mm",
      partNumber: "2N2AI20005",
      revision: "RevA",
      commercial_status: 0,
      inset: "5°",
      insetCode: 1,
      depth: null,
      depthCode: -1,
      diameter: "20mm",
      diameterCode: 0
    },
    {
      id: "2N2AI20010_Rev_A",
      name: "Inset 10° 20mm",
      shortName: "Inset 10",
      sizeLabel: "20mm",
      partNumber: "2N2AI20010",
      revision: "RevA",
      commercial_status: 0,
      inset: "10°",
      insetCode: 2,
      depth: null,
      depthCode: -1,
      diameter: "20mm",
      diameterCode: 0
    }
  ],
  scapular_reverse_configs: {
    baseplates_configs: {
      baseplate_stem_types: [
        {
          id: 0,
          displayName: "15mm",
          displayOrder: 1
        },
        {
          id: 1,
          displayName: "25mm",
          displayOrder: 2
        },
        {
          id: 2,
          displayName: "10mm",
          displayOrder: 0
        }
      ],
      baseplate_diameters: [
        {
          id: 0,
          displayName: "24mm",
          displayOrder: 0,
          diameterValue: 24
        },
        {
          id: 1,
          displayName: "27mm",
          displayOrder: 1,
          diameterValue: 27
        }
      ],
      baseplate_inclinations: [
        {
          id: 0,
          displayName: "0°",
          displayOrder: 0,
          angleValue: 0
        },
        {
          id: 1,
          displayName: "5°",
          displayOrder: 1,
          angleValue: 5
        },
        {
          id: 2,
          displayName: "10°",
          displayOrder: 2,
          angleValue: 10
        },
        {
          id: 3,
          displayName: "15°",
          displayOrder: 3,
          angleValue: 15
        }
      ],
      baseplate_thicknesses: [
        {
          id: 0,
          displayName: "0mm offset",
          displayOrder: 0,
          thicknessValue: 0
        },
        {
          id: 1,
          displayName: "3mm offset",
          displayOrder: 1,
          thicknessValue: 3
        }
      ]
    },
    central_screws_configs: {
      central_screw_lengths: [
        {
          id: 0,
          displayName: "15mm",
          displayOrder: 0
        },
        {
          id: 1,
          displayName: "20mm",
          displayOrder: 1
        },
        {
          id: 2,
          displayName: "25mm",
          displayOrder: 2
        },
        {
          id: 3,
          displayName: "30mm",
          displayOrder: 3
        },
        {
          id: 4,
          displayName: "35mm",
          displayOrder: 4
        },
        {
          id: 5,
          displayName: "40mm",
          displayOrder: 5
        }
      ]
    },
    glenospheres_configs: {
      glenosphere_diameters: [
        {
          id: 0,
          displayName: "33mm",
          displayOrder: 0,
          diameterValue: 33
        },
        {
          id: 1,
          displayName: "36mm",
          displayOrder: 1,
          diameterValue: 36
        },
        {
          id: 2,
          displayName: "39mm",
          displayOrder: 2,
          diameterValue: 39
        },
        {
          id: 3,
          displayName: "42mm",
          displayOrder: 3,
          diameterValue: 42
        }
      ],
      glenosphere_offsets: [
        {
          id: 0,
          displayName: "0mm",
          displayOrder: 0,
          offsetValue: 0
        },
        {
          id: 1,
          displayName: "3mm",
          displayOrder: 1,
          offsetValue: 3
        },
        {
          id: 2,
          displayName: "6mm",
          displayOrder: 2,
          offsetValue: 6
        }
      ]
    }
  },
  reverse: {
    baseplates: [
      {
        id: "2R1T240010_Rev_C",
        name: "24mm baseplate 10mm stem with 0° inclination",
        shortName: "24mm baseplate",
        partNumber: "2R1T240010",
        revision: "RevC",
        commercial_status: 0,
        diameter: "24mm",
        diameterCode: 0,
        stem: "10mm",
        stemCode: 2,
        inclination: "0°",
        inclinationCode: 0,
        thickness: null,
        thicknessCode: -1
      },
      {
        id: "2R1T240015_Rev_C",
        name: "24mm baseplate 15mm stem with 0° inclination with 3mm offset",
        shortName: "24mm baseplate",
        partNumber: "2R1T240015",
        revision: "RevC",
        commercial_status: 1,
        diameter: "24mm",
        diameterCode: 0,
        stem: "15mm",
        stemCode: 0,
        inclination: "0°",
        inclinationCode: 0,
        thickness: "3mm offset",
        thicknessCode: 1
      }
    ],
    glenospheres: [
      {
        id: "2R1GEX2036_Rev_C",
        name: "Eccentric glenosphere 36mm",
        shortName: "36mm eccentric glenosphere",
        partNumber: "2R1GEX2036",
        revision: "RevC",
        commercial_status: 2,
        type: 1,
        diameter: "36mm",
        diameterCode: 1,
        offset: null,
        offsetCode: -1
      },
      {
        id: "2R1GLX0333_Rev_C",
        name: "33mm diameter standard glenosphere with 3mm offset",
        shortName: "33mm standard glenosphere",
        partNumber: "2R1GLX0333",
        revision: "RevC",
        commercial_status: 0,
        address: "2R1GLX0333 RSA Lateralized Glenosphere RevC",
        type: 0,
        diameter: "33mm",
        diameterCode: 0,
        offset: "3mm",
        offsetCode: 1
      }
    ],
    centralScrews: [
      {
        id: "2R1PC60015_Rev_F",
        name: "Central screw 15mm",
        shortName: "15mm central screw",
        partNumber: "2R1PC60015",
        revision: "RevF",
        commercial_status: 0,
        length: "15mm",
        lengthCode: 5
      }
    ]
  },
  humeral_anatomic_configs: {
    eccentric_heads_configs: {
      eccentric_head_diameters: [
        {
          id: 0,
          displayName: "38mm",
          diameterValue: 38,
          displayOrder: 0
        },
        {
          id: 1,
          displayName: "40mm",
          diameterValue: 40,
          displayOrder: 1
        },
        {
          id: 2,
          displayName: "42mm",
          diameterValue: 42,
          displayOrder: 2
        },
        {
          id: 3,
          displayName: "44mm",
          diameterValue: 44,
          displayOrder: 3
        },
        {
          id: 4,
          displayName: "46mm",
          diameterValue: 46,
          displayOrder: 4
        },
        {
          id: 5,
          displayName: "48mm",
          diameterValue: 48,
          displayOrder: 5
        },
        {
          id: 6,
          displayName: "50mm",
          diameterValue: 50,
          displayOrder: 6
        },
        {
          id: 7,
          displayName: "52mm",
          diameterValue: 52,
          displayOrder: 7
        },
        {
          id: 8,
          displayName: "56mm",
          diameterValue: 56,
          displayOrder: 8
        }
      ],
      eccentric_head_thicknesses: [
        {
          id: 0,
          displayName: "14mm",
          displayOrder: 0
        },
        {
          id: 1,
          displayName: "15mm",
          displayOrder: 1
        },
        {
          id: 2,
          displayName: "16mm",
          displayOrder: 2
        },
        {
          id: 3,
          displayName: "17mm",
          displayOrder: 3
        },
        {
          id: 4,
          displayName: "18mm",
          displayOrder: 4
        },
        {
          id: 5,
          displayName: "19mm",
          displayOrder: 5
        },
        {
          id: 6,
          displayName: "20mm",
          displayOrder: 6
        },
        {
          id: 7,
          displayName: "21mm",
          displayOrder: 7
        }
      ]
    }
  },
  humeral_anatomic: {
    eccentric_heads: [
      {
        commercial_status: 0,
        id: "2N2HE14038_RevA",
        diameter: "38mm",
        thickness: "14mm",
        name: "38mm diameter eccentric head with 14mm thickness",
        displayName: "38mm eccentric head",
        address:
          "2N2HE14038 - Offset Humeral Head 14mm Thickness x 38mm Diameter RevA",
        diameterCode: 0,
        thicknessCode: 0
      }
    ]
  },
  humeral_reverse_configs: {
    defaultMedialization: 0.078,
    tray_configs: {
      tray_diameters: [
        {
          id: 0,
          displayName: "38mm",
          displayOrder: 0
        },
        {
          id: 1,
          displayName: "44mm",
          displayOrder: 1
        }
      ],
      tray_offsets: [
        {
          id: 0,
          displayName: "0mm",
          displayOrder: 0
        },
        {
          id: 1,
          displayName: "6mm",
          displayOrder: 1
        }
      ]
    },
    bearing_configs: {
      bearing_diameters: [
        {
          id: 0,
          displayName: "33mm",
          displayOrder: 0
        },
        {
          id: 1,
          displayName: "36mm",
          displayOrder: 1
        },
        {
          id: 2,
          displayName: "39mm",
          displayOrder: 2
        },
        {
          id: 3,
          displayName: "42mm",
          displayOrder: 3
        }
      ],
      bearing_thicknesses: [
        {
          id: 0,
          displayName: "0mm",
          displayOrder: 0
        },
        {
          id: 1,
          displayName: "3mm",
          displayOrder: 1
        },
        {
          id: 2,
          displayName: "6mm",
          displayOrder: 2
        }
      ],
      constrained_bearing_angles: [
        {
          id: 0,
          displayName: "5°",
          displayOrder: 0
        },
        {
          id: 1,
          displayName: "10°",
          displayOrder: 1
        }
      ]
    }
  },
  humeral_reverse: {
    trays: [
      {
        id: "2R1M380001_RevG",
        name: "Humeral Tray 0mm offset x 38mm diameter Rev G",
        shortName: "Humeral Tray 0mm x 38mm",
        partNumber: "2R1M380001",
        revision: "RevG",
        commercial_status: 0,
        diameter: "38mm",
        diameterCode: 0,
        offset: "0mm",
        offsetCode: 0
      }
    ],
    bearings: [
      {
        id: "2R1P100033_RevG",
        name: "Humeral Bearing 0mm thickness x 33mm diameter RevG",
        shortName: "Humeral Bearing 0mm x 33mm",
        partNumber: "2R1P100033",
        revision: "RevG",
        commercial_status: 2,
        type: "Thickness",
        typeCode: 0,
        diameter: "33mm",
        diameterCode: 0,
        thickness: "0mm",
        thicknessCode: 0,
        angle: null,
        angleCode: -1
      }
    ]
  },
  humeral_common_configs: {
    stem_configs: {
      stem_types: [
        {
          id: 0,
          displayName: "Stemless",
          displayOrder: 0,
          isForAnatomic: true,
          isForReverse: false
        },
        {
          id: 1,
          displayName: "Short Stem",
          displayOrder: 1,
          isForAnatomic: true,
          isForReverse: true
        },
        {
          id: 2,
          displayName: "InSet 95",
          displayOrder: 2,
          isForAnatomic: true,
          isForReverse: true
        }
      ],
      stem_sizes: [
        {
          id: 0,
          displayName: "0: 28mm",
          displayOrder: 0
        },
        {
          id: 1,
          displayName: "1: 32mm",
          displayOrder: 1
        },
        {
          id: 2,
          displayName: "1.5: 34mm",
          displayOrder: 2
        },
        {
          id: 3,
          displayName: "2: 38mm",
          displayOrder: 3
        },
        {
          id: 4,
          displayName: "3: 44mm",
          displayOrder: 4
        },
        {
          id: 5,
          displayName: "6mm",
          displayOrder: 5
        },
        {
          id: 6,
          displayName: "7mm",
          displayOrder: 6
        },
        {
          id: 7,
          displayName: "8mm",
          displayOrder: 7
        },
        {
          id: 8,
          displayName: "9mm",
          displayOrder: 8
        },
        {
          id: 9,
          displayName: "10mm",
          displayOrder: 9
        },
        {
          id: 10,
          displayName: "11mm",
          displayOrder: 10
        },
        {
          id: 11,
          displayName: "12mm",
          displayOrder: 11
        }
      ]
    }
  },
  humeral_common: {
    stems: [
      {
        id: "2N2SN06280_RevA",
        name: "28mm",
        displayName: "0: 28mm",
        partNumber: "2N2SN06280",
        revision: "RevA",
        address: "2N2SN06280 28mm Gen 2 Stem 70 mm x 6mm  RevA",
        stemType: 1,
        stemSize: 0,
        commercial_status: 1
      },
      {
        id: "2G2SC21280_RevC",
        name: "28mm",
        displayName: "0: 28mm",
        partNumber: "2G2SC21280",
        revision: "RevC",
        address: "2G2SC21280 28mm Stemless Implant RevC",
        stemType: 0,
        stemSize: 0,
        commercial_status: 1
      },
      {
        id: "2L1S060001_RevB",
        name: "6mm",
        displayName: "6mm",
        partNumber: "2L1S060001",
        revision: "RevB",
        address: "2L1S060001 06mm Primary Long Stem 95mm RevB",
        stemType: 2,
        stemSize: 5,
        commercial_status: 1
      }
    ]
  }
};

export const mockedReversePlanning = {
  Procedure: 1,
  PlanData: { name: "Plan 3", idx: 2, isFavorite: false },
  ImplantData: {
    ImplantCode: "2R1T240010_Rev_C",
    CentralScrewCode: "2R1PC60015_Rev_F",
    GlenosphereCode: "2R1GEX2036_Rev_C",
    SecondaryInclination: 292.5,
    SecondaryVersion: 123.456,
    Rotation: 1.35,
    Inclination: 12.34,
    Version: 43.21,
    Lateralization: 54.32,
    RimFullSeating: 0.4765625,
    RimPartialSeating: 0.0078125,
    Seating: 0.4916041188240051,
    ReverseOptsData: {
      ImplantModel: 0,
      BaseplateDiameter: 0,
      BaseplateStem: 0,
      BaseplateAngle: 0,
      CentralScrew: 0,
      BaseplateType: 0,
      GlenosphereDiameter: 0,
      GlenosphereOffset: 0,
      GlenosphereType: 0,
      GlenosphereRotation: 0
    }
  },
  HumeralPointsDifferFromDefaults: 0,
  HumeralImplantData: {
    ReverseResectionPlaneInclination: 10,
    ReverseResectionPlaneFullRotation: 10,
    HumeralStemCode: "2N2SN06280_RevA",
    HumeralReverseTrayCode: "2R1M380001_RevG",
    HumeralReverseBearingCode: "2R1P100033_RevG",
    HumeralHeadCutDiameter: 0.055111,
    ReverseImplantLatMedDisplayOffset: 0.0594,
    ReverseImplantPostAntOffset: 0.0123,
    ReverseImplantOptions: {
      StemType: 1
    }
  }
};

export const mockedAnatomicPlanning = {
  Procedure: 0,
  PlanData: { name: "Plan 2", idx: 1, isFavorite: false },
  ViewConstructData: {
    LatMedValue: 1,
    ProxDistValue: 2,
    PostAntValue: 3
  },
  ImplantData: {
    ImplantCode: "0S0CI20006_Rev_A",
    SecondaryInclination: 292.5,
    SecondaryVersion: 123.456,
    Rotation: 1.35,
    Inclination: 12.34,
    Version: 43.21,
    Lateralization: 54.32
  },
  ReverseOptsData: {},
  HumeralPointsDifferFromDefaults: 0,
  HumeralImplantData: {
    AnatomicImplantOptions: {
      StemType: 0
    },
    AnatomicResectionPlaneFullRotation: -10,
    HumeralStemCode: "2G2SC21280_RevC",
    HumeralAnatomicHeadCode: "2N2HE14038_RevA",
    HumeralHeadCutDiameter: 0.055111,
    AnatomicResectionPlaneInclination: 0.1234,
    AnatomicHeadRotation: 3.0,
    AnatomicImplantLatMedOffset: 0.0594,
    AnatomicImplantPostAntOffset: 0.0123,
    AnatomicImplantTranslation: 0.1234
  }
};

export const mockedHumeralAnatomicImplant =
  mockedImplantsInfo.humeral_common.stems[1];
export const mockedHumeralReverseImplant =
  mockedImplantsInfo.humeral_common.stems[0];
export const mockedTrayImplant = mockedImplantsInfo.humeral_reverse.trays[0];
export const mockedBearingImplant =
  mockedImplantsInfo.humeral_reverse.bearings[0];
export const mockedEccentricHeadImplant =
  mockedImplantsInfo.humeral_anatomic.eccentric_heads[0];
