import React from "react";
import PropTypes from "prop-types";
import { Input } from "antd";
import { buttonSize } from "../baseComponents/baseComponentTypes";
import "./BaseTextInput.less";
const defaultConfig = {
  placeholder: "",
  maxLength: 255,
  autoFocus: false,
  value: "",
  disabled: false,
  id: undefined
};

const BaseTextInput = ({
  config = defaultConfig,
  onChange = null,
  onPressEnter = null,
  childClassName = ""
}) => {
  config = { ...defaultConfig, ...config };
  if (config.isPassword) {
    return (
      <Input.Password
        id={config.id}
        className="input-container"
        onChange={onChange}
        placeholder={config.placeholder}
        maxLength={config.maxlength}
        autoFocus={config.autoFocus}
        value={config.value}
        disabled={config.disabled}
      />
    );
  } else {
    return (
      <Input
        id={config.id}
        className={"input-container" + childClassName}
        onChange={onChange}
        onPressEnter={onPressEnter}
        placeholder={config.placeholder}
        maxLength={config.maxlength}
        autoFocus={config.autoFocus}
        value={config.value}
        disabled={config.disabled}
        size={buttonSize.SMALL}
      />
    );
  }
};
BaseTextInput.propTypes = {
  config: PropTypes.object,
  onChange: PropTypes.func,
  onPressEnter: PropTypes.func,
  childClassName: PropTypes.string
};

export default BaseTextInput;
