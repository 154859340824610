import React from "react";
import PropTypes from "prop-types";
import { Layout } from "antd";
import BreadcrumbContainer from "../common/breadcrumb/BreadcrumbContainer";
import "./GenericHeader.less";

const { Header } = Layout;

const GenericHeader = ({ title, breadcrumbRoutes = null }) => {
  return (
    <Header>
      <div className="header-title">
        {breadcrumbRoutes ? (
          <BreadcrumbContainer routes={breadcrumbRoutes} />
        ) : (
          title
        )}
      </div>
    </Header>
  );
};

GenericHeader.propTypes = {
  title: PropTypes.string,
  breadcrumbRoutes: PropTypes.array
};

export default React.memo(GenericHeader);
