import React from "react";
import PropTypes from "prop-types";
import { isEmpty, omit } from "lodash";
import { Icon as LegacyIcon } from "@ant-design/compatible";
import { Tabs, Tooltip } from "antd";
import PlanningInfoTab from "./planningComponents/PlanningInfoTab";
import PlanningResult from "./planningComponents/PlanningResult";
import { PlanningNotAvailable } from "./planningComponents";
import AlertComponent, {
  alertTypes
} from "../common/baseComponents/AlertComponent";
import {
  getCasesWithSpecialAttention,
  getHumeralCasesWithSpecialAttention,
  showSpecialAttentionIcon,
  getProcedureName,
  measurementsIncludesHumeralPoints,
  TAB_TYPES,
  getTabTooltipMessage
} from "../../helpers/launchReconHelper";
import "./SurgeryPlanningInfo.less";

const { TabPane } = Tabs;

const getSpecialAttentionIconClassName = (activeTab, index) => {
  return (
    <LegacyIcon
      type="exclamation-circle"
      theme="filled"
      className={
        parseInt(activeTab) === index
          ? "special-attention-icon-active"
          : "special-attention-icon"
      }
    />
  );
};

const getSpecialAttentionWarning = specialAttentionCases => {
  return (
    !isEmpty(specialAttentionCases) && (
      <div>
        <AlertComponent
          config={{
            className: "alert-component special-attention",
            message:
              "This case has at least one plan with an item that requires special attention.",
            showIcon: true,
            icon: <LegacyIcon type="exclamation-circle" theme="filled" />,
            type: alertTypes.ERROR
          }}
        />
      </div>
    )
  );
};

const disableTabNoHumerus = (plan, surgeryMeasurements) => {
  return (
    !measurementsIncludesHumeralPoints(surgeryMeasurements) ||
    !plan.data?.HumeralImplantData
  );
};

const renderScapulaTab = (
  casesWithSpecialAttention,
  plan,
  activePlanType,
  surgeryMeasurements,
  operationSideValue,
  implantsInfo
) => {
  return (
    <TabPane
      key={0}
      forceRender={true}
      tab={
        <span>
          {!isEmpty(casesWithSpecialAttention) &&
            showSpecialAttentionIcon(plan.data, casesWithSpecialAttention) &&
            getSpecialAttentionIconClassName(activePlanType, 0)}
          Scapula
        </span>
      }
    >
      <PlanningInfoTab
        type={TAB_TYPES.SCAPULA}
        surgeryPlanning={plan.data}
        surgeryMeasurements={surgeryMeasurements}
        operationSideValue={operationSideValue}
        lastModifiedPlanningDate={plan.lastModifiedPlanningDate}
        casesWithSpecialAttention={casesWithSpecialAttention}
        implantsInfo={implantsInfo}
      />
    </TabPane>
  );
};

const renderHumeralTab = (
  plan,
  surgeryMeasurements,
  humeralCasesWithSpecialAttention,
  activePlanType,
  operationSideValue,
  implantsInfo
) => {
  return (
    <TabPane
      key={1}
      forceRender={true}
      disabled={disableTabNoHumerus(plan, surgeryMeasurements)}
      tab={
        <span>
          <Tooltip
            placement="bottom"
            title={getTabTooltipMessage(
              disableTabNoHumerus(plan, surgeryMeasurements),
              TAB_TYPES.HUMERUS
            )}
          >
            {!isEmpty(humeralCasesWithSpecialAttention) &&
              showSpecialAttentionIcon(
                plan.data,
                humeralCasesWithSpecialAttention
              ) &&
              getSpecialAttentionIconClassName(activePlanType, 1)}
            Humerus
          </Tooltip>
        </span>
      }
    >
      {plan.data?.HumeralImplantData && (
        <PlanningInfoTab
          type={TAB_TYPES.HUMERUS}
          surgeryPlanning={plan.data}
          surgeryMeasurements={surgeryMeasurements}
          operationSideValue={operationSideValue}
          lastModifiedPlanningDate={plan.lastModifiedPlanningDate}
          casesWithSpecialAttention={humeralCasesWithSpecialAttention}
          implantsInfo={implantsInfo}
        />
      )}
    </TabPane>
  );
};

const renderConstructTab = (
  plan,
  surgeryMeasurements,
  operationSideValue,
  humeralCasesWithSpecialAttention,
  implantsInfo
) => {
  return (
    <TabPane
      key={2}
      forceRender={true}
      disabled={
        !plan.data.PlanningData?.HasEnteredViewConstructMode ||
        disableTabNoHumerus(plan, surgeryMeasurements)
      }
      tab={
        <span>
          <Tooltip
            placement="bottom"
            title={getTabTooltipMessage(
              !plan.data.PlanningData?.HasEnteredViewConstructMode ||
                disableTabNoHumerus(plan, surgeryMeasurements),
              TAB_TYPES.CONSTRUCT,
              disableTabNoHumerus(plan, surgeryMeasurements)
                ? "unavailable"
                : "disabled"
            )}
          >
            Construct
          </Tooltip>
        </span>
      }
    >
      {plan.data?.ViewConstructData && (
        <PlanningInfoTab
          type={TAB_TYPES.CONSTRUCT}
          surgeryPlanning={plan.data}
          surgeryMeasurements={surgeryMeasurements}
          operationSideValue={operationSideValue}
          lastModifiedPlanningDate={plan.lastModifiedPlanningDate}
          casesWithSpecialAttention={humeralCasesWithSpecialAttention}
          implantsInfo={implantsInfo}
        />
      )}
    </TabPane>
  );
};

const renderPlanningInfo = (
  caseInfo,
  activeTab,
  setActiveTabFunction,
  setActivePlanTypeFunction,
  activePlanType
) => {
  const {
    casesWithSpecialAttention,
    humeralCasesWithSpecialAttention,
    surgeryPlanning,
    surgeryMeasurements,
    operationSideValue,
    implantsInfo
  } = caseInfo;
  return (
    <div className="launch-component planning-info">
      <div className="card-content">
        {getSpecialAttentionWarning({
          ...casesWithSpecialAttention,
          ...humeralCasesWithSpecialAttention
        })}
        <Tabs
          type="card"
          activeKey={activeTab}
          animated={false}
          onChange={key => {
            setActiveTabFunction(key);
            setActivePlanTypeFunction("0");
          }}
        >
          {Array.isArray(surgeryPlanning) &&
            surgeryPlanning.map((plan, index) => (
              <TabPane
                forceRender={true}
                tab={
                  <span>
                    {showSpecialAttentionIcon(plan.data, {
                      ...casesWithSpecialAttention,
                      ...humeralCasesWithSpecialAttention
                    }) && getSpecialAttentionIconClassName(activeTab, index)}
                    {plan.data.PlanData.name}
                    {plan.data.PlanData.isFavorite && (
                      <LegacyIcon
                        type="star"
                        theme="filled"
                        className={
                          parseInt(activeTab) === index
                            ? "favorite-icon-active"
                            : "favorite-icon"
                        }
                      />
                    )}
                  </span>
                }
                key={index}
              >
                <PlanningResult
                  planningResultInfo={[
                    {
                      values: [
                        {
                          value: plan.data["PlanData"]["name"],
                          attribute: "Plan name"
                        },
                        {
                          value: getProcedureName(plan.data),
                          attribute: "Procedure"
                        }
                      ]
                    }
                  ]}
                />
                <Tabs
                  activeKey={activePlanType}
                  animated={false}
                  className="plan-type"
                  onChange={key => setActivePlanTypeFunction(key)}
                >
                  {renderScapulaTab(
                    casesWithSpecialAttention,
                    plan,
                    activePlanType,
                    surgeryMeasurements,
                    operationSideValue,
                    implantsInfo
                  )}
                  {renderHumeralTab(
                    plan,
                    surgeryMeasurements,
                    humeralCasesWithSpecialAttention,
                    activePlanType,
                    operationSideValue,
                    implantsInfo
                  )}
                  {renderConstructTab(
                    plan,
                    surgeryMeasurements,
                    operationSideValue,
                    humeralCasesWithSpecialAttention,
                    implantsInfo
                  )}
                </Tabs>
              </TabPane>
            ))}
        </Tabs>
      </div>
    </div>
  );
};

const SurgeryPlanningInfo = ({
  surgeryPlanning,
  surgeryMeasurements,
  operationSideValue,
  activeTab,
  setActiveTab,
  activePlanType,
  setActivePlanType,
  implantsInfo
}) => {
  const scapularImplatsInfo = omit(implantsInfo, [
    "humeral_reverse",
    "humeral_anatomic"
  ]);

  const humeralImplatsInfo = omit(implantsInfo, [
    "anatomicDefaultImplantId",
    "reverseDefaultImplantId",
    "anatomic",
    "reverse"
  ]);

  if (
    !isEmpty(surgeryPlanning) &&
    !isEmpty(surgeryMeasurements.patientProfileData)
  ) {
    const casesWithSpecialAttention = getCasesWithSpecialAttention(
      surgeryPlanning,
      scapularImplatsInfo
    );
    const humeralCasesWithSpecialAttention =
      getHumeralCasesWithSpecialAttention(surgeryPlanning, humeralImplatsInfo);

    return renderPlanningInfo(
      {
        casesWithSpecialAttention,
        humeralCasesWithSpecialAttention,
        surgeryPlanning,
        surgeryMeasurements,
        operationSideValue,
        implantsInfo
      },
      activeTab,
      setActiveTab,
      setActivePlanType,
      activePlanType
    );
  } else {
    return <PlanningNotAvailable />;
  }
};

SurgeryPlanningInfo.propTypes = {
  surgeryPlanning: PropTypes.array,
  surgeryMeasurements: PropTypes.object,
  operationSideValue: PropTypes.string,
  activeTab: PropTypes.string,
  setActiveTab: PropTypes.func,
  activePlanType: PropTypes.string,
  setActivePlanType: PropTypes.func,
  implantsInfo: PropTypes.object
};

export default SurgeryPlanningInfo;
