import React from "react";
import PropTypes from "prop-types";
import { Select } from "antd";
import { buttonSize } from "../baseComponents/baseComponentTypes";
import "./BaseSelect.less";
const { Option } = Select;

const BaseSelect = ({ config = null, onChange = null }) => {
  const options = config?.options ? config.options : [];
  const disabled = config?.disabled ? config.disabled : false;
  const optionComponents = Object.keys(options).map(option => {
    return (
      <Option key={option} value={option}>
        {options[option]}
      </Option>
    );
  });

  return (
    <Select
      className={disabled ? "input-container-disabled" : "input-container"}
      getPopupContainer={trigger => trigger.parentNode}
      defaultValue={config?.defaultValue}
      value={config?.value}
      onChange={onChange}
      size={buttonSize.SMALL}
      disabled={disabled}
      placeholder={config ? config.placeholder : ""}
    >
      {optionComponents}
    </Select>
  );
};

BaseSelect.propTypes = {
  config: PropTypes.object,
  onChange: PropTypes.func
};

export default BaseSelect;
