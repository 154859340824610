import { isEmpty, compact, isEqual } from "lodash";
import {
  GLENOSPHERE_TYPE,
  REVERSE_CATEGORY,
  REVERSE_CATEGORY_CODE,
  COMMERCIAL_AVAILABILITY,
  HUMERAL_ANATOMIC_CATEGORY,
  EMPTY_DATA,
  HUMERAL_REVERSE_CATEGORY_CODE,
  HUMERAL_ANATOMIC_CATEGORY_CODE,
  HUMERAL_REVERSE_CATEGORY,
  parsedOperationSideValues,
  latMedValues,
  postAntValues,
  HUMERAL_SURGERY_PROPERTIES_LABELS,
  HUMERAL_PATIENT_ANATOMY_LABELS
} from "./consts";
import { bankersRound, evenRound } from "./caseHelper";

const MEASUREMENTS_LABEL = {
  SURGEON_MODIFIED: "Surgeon modified",
  AUTOMATED: "Automated"
};

const checkValidPointsData = pointsArray => {
  const zeroCoordinates = { x: 0, y: 0, z: 0 };
  const invalidValues = [null, undefined, NaN, 0];

  return !pointsArray.some(value => {
    return invalidValues.includes(value) || isEqual(value, zeroCoordinates);
  });
};

export const checkIsNumber = valueToCheck => {
  return typeof valueToCheck === "number";
};

const UNIT_TYPES = {
  DEGREES: "°",
  DISTANCE: "mm"
};

const parseMeasurementValue = (value, unit, decimals) => {
  const getDefaultDecimals = () => {
    switch (unit) {
      case UNIT_TYPES.DEGREES:
        return 1;
      case UNIT_TYPES.DISTANCE:
        return 2;
      default:
        return 2;
    }
  };

  const decimalPlaces = decimals ?? getDefaultDecimals();

  return parseToDecimals(value, decimalPlaces, true);
};

export const measurementFactory = unit => {
  return (originalValue, recalculatedValue, decimals = null) => {
    if (recalculatedValue) {
      return `${recalculatedValue}${unit}`;
    } else {
      return checkIsNumber(originalValue)
        ? `${parseMeasurementValue(originalValue, unit, decimals)}${unit}`
        : EMPTY_DATA;
    }
  };
};

export const getDistance = measurementFactory(UNIT_TYPES.DISTANCE);
export const getDegrees = measurementFactory(UNIT_TYPES.DEGREES);

export const parseToDecimals = (
  numberToFix,
  decimals = 2,
  removeZeros = false
) => {
  const res = numberToFix.toFixed(decimals).replace(/\.00$/, "");

  return removeZeros ? res.replace(/(\.0*|(?:(\.\d+?)0+))$/, "$2") : res;
};

export const getGlenoidDiameter = (implant, implantsInfo) => {
  const diameters = implantsInfo.scapular_anatomic_configs.implant_diameters;

  const selectedDiameter = diameters.find(
    diameter => diameter.id === implant?.diameterCode
  );

  return selectedDiameter?.displayName || EMPTY_DATA;
};

export const getGlenoidThickness = (implant, implantsInfo) => {
  const thicknesses =
    implantsInfo.scapular_anatomic_configs.implant_thicknesses;

  const selectedThickness = thicknesses.find(
    thickness => thickness.id === implant?.depthCode
  );

  return selectedThickness?.displayName || EMPTY_DATA;
};

export const getMedLat = medLatValue => {
  return evenRound(medLatValue, 4) * 1000 >= 0
    ? latMedValues.MEDIALIZATION
    : latMedValues.LATERALIZATION;
};

export const parseMedLat = medLatValueToParse => {
  return getDistance(
    medLatValueToParse,
    Math.abs(evenRound(medLatValueToParse * 1000, 1))
  );
};
export const parseHumerusSubluxation = humerusSubluxation => {
  return parseSubluxationCalc(humerusSubluxation);
};

export const parseSubluxationCalc = humerusSubluxation => {
  const isNumber = checkIsNumber(humerusSubluxation);
  return isNumber && humerusSubluxation > 0 && humerusSubluxation <= 1
    ? `${bankersRound(100 * humerusSubluxation)}%`
    : EMPTY_DATA;
};

export const parseRotation = glenoidRotation => {
  let rotationDegrees = (glenoidRotation / 12) * 360;
  if (rotationDegrees > 180) {
    rotationDegrees = rotationDegrees - 360;
  }
  return bankersRound(rotationDegrees);
};

export const parseInclination = glenoidInclination => {
  const isBetweenOne = glenoidInclination > -1 && glenoidInclination < 1;
  if (isBetweenOne) {
    return "Inclination";
  }
  if (glenoidInclination >= 1) {
    return "Inferior";
  }
  return "Superior";
};

export const parseVersion = (version, operationSideVal) => {
  let res;
  if (version > -1 && version < 1) {
    res = "Version";
  } else if (operationSideVal === parsedOperationSideValues.LEFT) {
    res = version >= 1 ? "Anteversion" : "Retroversion";
  } else {
    res = version >= 1 ? "Retroversion" : "Anteversion";
  }

  return res;
};

export const parseRim = rimValueToParse => {
  return getDegrees(rimValueToParse, bankersRound(rimValueToParse * 360));
};

export const getNoRimInset = (rimFullSeating, rimPartialSeating) => {
  return rimFullSeating !== undefined && rimPartialSeating !== undefined
    ? bankersRound(
        360 -
          bankersRound(rimFullSeating * 360) -
          bankersRound(rimPartialSeating * 360)
      ) + "°"
    : EMPTY_DATA;
};

export const parseSeating = seatingToParse => {
  return seatingToParse !== undefined
    ? bankersRound(seatingToParse * 100) + "%"
    : EMPTY_DATA;
};

const getGlenoidVersion = (operationSideVal, glenoidVersion) => {
  if (operationSideVal === parsedOperationSideValues.RIGHT) {
    return glenoidVersion > 0 ? "Glenoid anteversion" : "Glenoid retroversion";
  }
  return glenoidVersion > 0 ? "Glenoid retroversion" : "Glenoid anteversion";
};

export const parseGlenoidVersion = (glenoidVersion, operationSideVal) => {
  let res = EMPTY_DATA;

  if (operationSideVal) {
    if (glenoidVersion === 0) {
      res = "Glenoid version";
    } else {
      res = getGlenoidVersion(operationSideVal, glenoidVersion);
    }
  }

  return res;
};

export const getNoSeating = seating => {
  return seating !== undefined
    ? bankersRound(100 - bankersRound(seating * 100)) + "%"
    : EMPTY_DATA;
};

export const getSelectedScapularAnatomicImplant = (
  implantCode,
  implantsInfo
) => {
  const anatomicImplants = implantsInfo.anatomic;

  return anatomicImplants.find(implant => implant.id === implantCode);
};

export const getSelectedBaseplate = (implantId, implantsInfo) => {
  const baseplates = implantsInfo.reverse.baseplates;

  return baseplates.find(implant => implant.id === implantId);
};

export const getSelectedCentralScrew = (implantId, implantsInfo) => {
  const centralScrews = implantsInfo.reverse.centralScrews;

  return centralScrews.find(implant => implant.id === implantId);
};

export const getCentralScrewLength = (implant, implantsInfo) => {
  const centralScrewConfigs =
    implantsInfo.scapular_reverse_configs.central_screws_configs;
  const lengths = centralScrewConfigs.central_screw_lengths;

  const selectedLength = lengths.find(
    length => length.id === implant?.lengthCode
  );

  return selectedLength?.displayName || EMPTY_DATA;
};

export const getSelectedGlenosphere = (implantId, implantsInfo) => {
  const glenospheres = implantsInfo.reverse.glenospheres;

  return glenospheres.find(implant => implant.id === implantId);
};

export const getImplantType = (implant, implantsInfo) => {
  const types = implantsInfo.scapular_anatomic_configs.implant_insets;

  const selectedType = types.find(type => type.id === implant?.insetCode);

  return selectedType?.displayName || EMPTY_DATA;
};

export const getBasePlateAngle = (implant, implantsInfo) => {
  const basePlateConfigs =
    implantsInfo.scapular_reverse_configs.baseplates_configs;
  const angles = basePlateConfigs.baseplate_inclinations;

  const selectedAngle = angles.find(
    angle => angle.id === implant?.inclinationCode
  );

  return selectedAngle?.displayName || EMPTY_DATA;
};

export const getBaseplateThickness = (implant, implantsInfo) => {
  const basePlateConfigs =
    implantsInfo.scapular_reverse_configs.baseplates_configs;
  const thicknesses = basePlateConfigs.baseplate_thicknesses;

  const selectedThickness = thicknesses.find(
    thickness => thickness.id === implant?.thicknessCode
  );

  return selectedThickness?.displayName || EMPTY_DATA;
};

export const getBasePlateDiameter = (implant, implantsInfo) => {
  const basePlateConfigs =
    implantsInfo.scapular_reverse_configs.baseplates_configs;
  const diameters = basePlateConfigs.baseplate_diameters;

  const selectedDiameter = diameters.find(
    diameter => diameter.id === implant?.diameterCode
  );

  return selectedDiameter?.displayName || EMPTY_DATA;
};

export const getBasePlateStem = (implant, implantsInfo) => {
  const basePlateConfigs =
    implantsInfo.scapular_reverse_configs.baseplates_configs;
  const stems = basePlateConfigs.baseplate_stem_types;

  const selectedStem = stems.find(stem => stem.id === implant?.stemCode);

  return selectedStem?.displayName || EMPTY_DATA;
};

export const getGlenosphereDiameter = (implant, implantsInfo) => {
  const glenosphereConfigs =
    implantsInfo.scapular_reverse_configs.glenospheres_configs;

  const diameters = glenosphereConfigs.glenosphere_diameters;

  const selectedDiameter = diameters.find(
    diameter => diameter.id === implant?.diameterCode
  );

  return selectedDiameter?.displayName || EMPTY_DATA;
};

export const getGlenosphereLabel = (type = GLENOSPHERE_TYPE.STANDARD) => {
  return type === GLENOSPHERE_TYPE.STANDARD ? "Offset" : "Rotation";
};

export const getGlenosphereOffset = (implant, implantsInfo) => {
  const glenosphereConfigs =
    implantsInfo.scapular_reverse_configs.glenospheres_configs;

  const offsets = glenosphereConfigs.glenosphere_offsets;

  const selectedOffset = offsets.find(
    offset => offset.id === implant?.offsetCode
  );

  return selectedOffset?.displayName || EMPTY_DATA;
};

export const getGlenosphereRotation = rotation => {
  return getDegrees(rotation);
};

export const parseGlenosphereType = implant => {
  const parsedGlenosphereTypeValues = {
    [GLENOSPHERE_TYPE.STANDARD]: "Standard",
    [GLENOSPHERE_TYPE.ECCENTRIC]: "Eccentric"
  };
  return parsedGlenosphereTypeValues[implant.type] || EMPTY_DATA;
};

export const showImplantType = (
  parsedSurgeryPlanning,
  implant,
  implantsInfo
) => {
  return parsedSurgeryPlanning["Procedure"] === 0
    ? {
        value: getImplantType(implant, implantsInfo),
        attribute: "Implant"
      }
    : {};
};

export const getProcedureName = parsedSurgeryPlanning => {
  return parsedSurgeryPlanning["Procedure"] === 0 ? "Anatomic" : "Reverse";
};

export const getPocketDepth = depth => {
  return depth > 0
    ? `${(Math.round(depth * 10000) / 10).toFixed(1)}mm`
    : EMPTY_DATA;
};

export const parseGlenoidPatientAnatomy = value => {
  return getDegrees(value, Math.floor(Math.abs(value)));
};

export const getImplantDisclaimer = (
  casesWithSpecialAttention,
  ImplantData,
  itemCode = REVERSE_CATEGORY_CODE[REVERSE_CATEGORY.BASEPLATES]
) => {
  const implantCode = ImplantData[itemCode];
  return COMMERCIAL_AVAILABILITY[casesWithSpecialAttention[implantCode]];
};

export const getCasesWithSpecialAttention = (surgeryPlanning, implantsInfo) => {
  const commercialStatuses = {};

  surgeryPlanning.forEach(plan => {
    if (plan.data.Procedure === 0) {
      getCommercialStatuses(plan.data, implantsInfo, commercialStatuses);
    } else {
      Object.values(REVERSE_CATEGORY).forEach(reverseCategory => {
        getCommercialStatuses(
          plan.data,
          implantsInfo,
          commercialStatuses,
          reverseCategory
        );
      });
    }
  });

  return commercialStatuses;
};

const getHumeralImplantInfo = (planData, implantsInfo, category) => {
  const implantProcedureCode = planData.Procedure
    ? HUMERAL_REVERSE_CATEGORY_CODE
    : HUMERAL_ANATOMIC_CATEGORY_CODE;

  if (planData.HumeralImplantData) {
    const implantData = planData.HumeralImplantData;

    if (category === HUMERAL_REVERSE_CATEGORY.STEMS) {
      const stems = implantsInfo.humeral_common.stems;

      return Object.values(stems).find(
        implant => implant.id === implantData.HumeralStemCode
      );
    } else {
      const planProcedure = {
        0: "humeral_anatomic",
        1: "humeral_reverse"
      };
      const requestedImplantProcedure = planProcedure[planData.Procedure];
      const implantProcedure = implantsInfo[requestedImplantProcedure];
      const implants = implantProcedure[category];

      return Object.values(implants).find(
        implant => implant.id === implantData[implantProcedureCode[category]]
      );
    }
  }
};

const getHumeralImplantProperties = (
  planData,
  implantsInfo,
  category,
  defaultReturnValue = -1
) => {
  const implantInfo = getHumeralImplantInfo(planData, implantsInfo, category);
  return isEmpty(implantInfo)
    ? { commercialStatus: defaultReturnValue, id: null }
    : {
        commercialStatus: implantInfo["commercial_status"],
        id: implantInfo["id"]
      };
};

const getHumeralCommercialStatuses = (
  planData,
  implantsInfo,
  commercialStatuses,
  category = null
) => {
  const { commercialStatus, id } = getHumeralImplantProperties(
    planData,
    implantsInfo,
    category
  );

  if (commercialStatus > 0) {
    commercialStatuses[id] = commercialStatus;
  }
};

export const getHumeralImplant = (implantId, implantsInfo) => {
  const stems = implantsInfo?.humeral_common?.stems;

  return stems.find(implant => implant.id === implantId);
};

export const getEccentricHeadImplant = (implantId, implantsInfo) => {
  const eccentricHeads = implantsInfo.humeral_anatomic.eccentric_heads;

  return eccentricHeads.find(implant => implant.id === implantId);
};

export const getTrayImplant = (implantId, implantsInfo) => {
  const trays = implantsInfo.humeral_reverse.trays;

  return trays.find(implant => implant.id === implantId);
};

export const getBearingImplant = (implantId, implantsInfo) => {
  const bearings = implantsInfo.humeral_reverse.bearings;

  return bearings.find(implant => implant.id === implantId);
};

export const getStemName = (implantsInfo, type) => {
  const humeralCommonConfigs = implantsInfo.humeral_common_configs;
  const stemTypes = humeralCommonConfigs.stem_configs.stem_types;
  const stemType = stemTypes.find(stem => stem.id === type);
  return stemType?.displayName || EMPTY_DATA;
};

export const getHumeralImplantSize = implant => {
  return implant?.displayName || EMPTY_DATA;
};

export const getEccentricHeadDiameter = (implant, implantsInfo) => {
  const humeralAnatomicConfigs = implantsInfo.humeral_anatomic_configs;
  const diameters =
    humeralAnatomicConfigs.eccentric_heads_configs.eccentric_head_diameters;

  const selectedDiameter = diameters.find(
    diameter => diameter.id === implant?.diameterCode
  );

  return selectedDiameter?.displayName || EMPTY_DATA;
};

export const getEccentricHeadThickness = (implant, implantsInfo) => {
  const humeralAnatomicConfigs = implantsInfo.humeral_anatomic_configs;
  const thicknesses =
    humeralAnatomicConfigs.eccentric_heads_configs.eccentric_head_thicknesses;

  const selectedThickness = thicknesses.find(
    thickness => thickness.id === implant?.thicknessCode
  );

  return selectedThickness?.displayName || EMPTY_DATA;
};

export const getTrayDiameter = (implant, implantsInfo) => {
  const humeralReverseConfigs = implantsInfo.humeral_reverse_configs;
  const diameters = humeralReverseConfigs.tray_configs.tray_diameters;

  const selectedDiameter = diameters.find(
    diameter => diameter.id === implant?.diameterCode
  );

  return selectedDiameter?.displayName || EMPTY_DATA;
};

export const getTrayOffset = (implant, implantsInfo) => {
  const humeralReverseConfigs = implantsInfo.humeral_reverse_configs;
  const offsets = humeralReverseConfigs.tray_configs.tray_offsets;

  const selectedOffset = offsets.find(
    offset => offset.id === implant?.offsetCode
  );

  return selectedOffset?.displayName || EMPTY_DATA;
};

export const getBearingType = implant => {
  return implant?.type || EMPTY_DATA;
};

export const getBearingDiameter = (implant, implantsInfo) => {
  const humeralReverseConfigs = implantsInfo.humeral_reverse_configs;
  const diameters = humeralReverseConfigs.bearing_configs.bearing_diameters;

  const selectedDiameter = diameters.find(
    diameter => diameter.id === implant?.diameterCode
  );

  return selectedDiameter?.displayName || EMPTY_DATA;
};

export const getBearingAngle = (implant, implantsInfo) => {
  const humeralReverseConfigs = implantsInfo.humeral_reverse_configs;
  const angles =
    humeralReverseConfigs.bearing_configs.constrained_bearing_angles;

  const selectedAngle = angles.find(angle => angle.id === implant?.angleCode);

  return selectedAngle?.displayName || EMPTY_DATA;
};

export const getBearingThickness = (implant, implantsInfo) => {
  const humeralReverseConfigs = implantsInfo.humeral_reverse_configs;
  const thicknesses = humeralReverseConfigs.bearing_configs.bearing_thicknesses;

  const selectedThickness = thicknesses.find(
    thickness => thickness.id === implant?.thicknessCode
  );

  return selectedThickness?.displayName || EMPTY_DATA;
};

export const getHumeralCasesWithSpecialAttention = (
  surgeryPlanning,
  implantsInfo
) => {
  const commercialStatuses = {};

  try {
    surgeryPlanning.forEach(plan => {
      if (plan.data.Procedure === 0) {
        Object.values(HUMERAL_ANATOMIC_CATEGORY).forEach(anatomicCategory => {
          getHumeralCommercialStatuses(
            plan.data,
            implantsInfo,
            commercialStatuses,
            anatomicCategory
          );
        });
      } else {
        Object.values(HUMERAL_REVERSE_CATEGORY).forEach(reverseCategory => {
          getHumeralCommercialStatuses(
            plan.data,
            implantsInfo,
            commercialStatuses,
            reverseCategory
          );
        });
      }
    });
  } catch (error) {
    console.log(
      `Error while getting humeral cases with special attention: ${error}`
    );
  }

  return commercialStatuses;
};

export const showSpecialAttentionIcon = (
  planData,
  casesWithSpecialAttention
) => {
  const { HumeralImplantData, ImplantData, Procedure } = planData;

  const hasSpecialAttention = implantData => {
    return casesWithSpecialAttention[implantData?.ImplantCode] > 0;
  };

  const hasSpecialAttentionForCategory = (implantData, categoryCodes) => {
    return Object.values(categoryCodes).some(
      code => casesWithSpecialAttention[implantData[code]] > 0
    );
  };

  let humeral, scapular;

  if (Procedure === 0) {
    humeral =
      HumeralImplantData &&
      hasSpecialAttentionForCategory(
        HumeralImplantData,
        HUMERAL_ANATOMIC_CATEGORY_CODE
      );
  } else {
    humeral =
      HumeralImplantData &&
      hasSpecialAttentionForCategory(
        HumeralImplantData,
        HUMERAL_REVERSE_CATEGORY_CODE
      );
  }

  if (Procedure === 0) {
    scapular = ImplantData && hasSpecialAttention(ImplantData);
  } else {
    scapular =
      ImplantData &&
      hasSpecialAttentionForCategory(ImplantData, REVERSE_CATEGORY_CODE);
  }

  return humeral || scapular;
};

const getCommercialStatuses = (
  planData,
  implantsInfo,
  commercialStatuses,
  reverseCategory = null
) => {
  const commercialStatus = getImplantProperty(
    planData,
    implantsInfo,
    "commercial_status",
    reverseCategory
  );
  const implantId = getImplantProperty(
    planData,
    implantsInfo,
    "id",
    reverseCategory
  );
  if (commercialStatus > 0) {
    commercialStatuses[implantId] = commercialStatus;
  }
};

export const getImplantProperty = (
  planData,
  implantsInfo,
  searchedProperty,
  reverseCategory = REVERSE_CATEGORY.BASEPLATES,
  defaultReturnValue = -1
) => {
  const implantInfo = getImplantInfo(planData, implantsInfo, reverseCategory);
  return isEmpty(implantInfo)
    ? defaultReturnValue
    : implantInfo[searchedProperty];
};

const getImplantInfo = (planData, implantsInfo, reverseCategory) => {
  const planProcedure = {
    0: "anatomic",
    1: "reverse"
  };
  const requestedImplantProcedure = planProcedure[planData.Procedure];
  const implantData = planData.ImplantData;

  if (requestedImplantProcedure === planProcedure[0]) {
    return Object.values(implantsInfo[requestedImplantProcedure]).find(
      implant => implant.id === implantData.ImplantCode
    );
  } else {
    return Object.values(
      implantsInfo[requestedImplantProcedure][reverseCategory]
    ).find(
      implant =>
        implant.id === implantData[REVERSE_CATEGORY_CODE[reverseCategory]]
    );
  }
};

export const getHumeralInclinationValue = () => {
  return getDegrees(132.5);
};

export const getHumeralVersionValue = (
  surgeryMeasurements,
  operationSideValue
) => {
  const profileData = surgeryMeasurements?.patientProfileData;
  const humeralResectionPlaneData = profileData?.HumeralResectionPlaneData;

  let version = humeralResectionPlaneData?.Version;
  const isNegativeVersion = version < 0;

  version = checkIsNumber(version) && evenRound(Math.abs(version));

  if (
    checkIsNumber(version) &&
    ((operationSideValue === parsedOperationSideValues.LEFT &&
      !isNegativeVersion) ||
      (operationSideValue === parsedOperationSideValues.RIGHT &&
        isNegativeVersion))
  ) {
    version = version * -1;
  }

  return getDegrees(version);
};

export const getHumerusMiddleOffsetData = surgeryMeasurements => {
  const profileData = surgeryMeasurements?.patientProfileData;
  const humerusData = profileData?.HumerusData;
  const middleOffset = humerusData?.MiddleOffset;

  return getDegrees(middleOffset, Math.abs(Math.floor(middleOffset)));
};

export const getHumeralHeadDiameterData = surgeryMeasurements => {
  const profileData = surgeryMeasurements?.patientProfileData;
  const humerusData = profileData?.HumerusData;
  const humeralHeadDiameter = humerusData?.HumeralHeadDiameter;

  return getDistance(
    humeralHeadDiameter,
    Math.abs(evenRound(humeralHeadDiameter * 100, 1))
  );
};

export const getHeadCutDiameterData = humeralImplantData => {
  const headCutDiameter = humeralImplantData?.HumeralHeadCutDiameter;
  return getDistance(
    headCutDiameter,
    Math.abs(evenRound(headCutDiameter * 100, 1))
  );
};

export const getAnatomicHeadRotationData = humeralImplantData => {
  const headRotation = humeralImplantData?.AnatomicHeadRotation;
  return getDegrees(headRotation, Math.floor(headRotation));
};

export const getAnatomicResectionInclinationData = humeralImplantData => {
  const implantTranslation =
    humeralImplantData?.AnatomicResectionPlaneInclination;

  return getDegrees(
    implantTranslation,
    Math.abs(evenRound(132.5 + implantTranslation, 1))
  );
};

export const getReversePostAntOffset = humeralImplantData => {
  const offset = humeralImplantData?.ReverseImplantPostAntOffset;
  return getDistance(offset, Math.abs(evenRound(offset * 100, 1)));
};

const getAntPostLabel = (offset, operationSideValue) => {
  const leftSideCondition =
    offset > 0 ? postAntValues.ANTERIOR : postAntValues.POSTERIOR;
  const rightSideCondition =
    offset >= 0 ? postAntValues.POSTERIOR : postAntValues.ANTERIOR;

  return operationSideValue === parsedOperationSideValues.LEFT
    ? leftSideCondition
    : rightSideCondition;
};

export const getReversePostAntLabel = (
  humeralImplantData,
  operationSideValue
) => {
  const offset = humeralImplantData?.ReverseImplantPostAntOffset;

  return getAntPostLabel(offset, operationSideValue);
};

export const getReverseLatMedOffset = humeralImplantData => {
  const offset = humeralImplantData?.ReverseImplantLatMedDisplayOffset;
  return getDistance(offset, Math.abs(evenRound(offset * 100, 1)));
};

export const getReverseLatMedLabel = humeralImplantData => {
  const offset = humeralImplantData?.ReverseImplantLatMedDisplayOffset;
  return offset > 0 ? latMedValues.LATERALIZATION : latMedValues.MEDIALIZATION;
};

export const getAnatomicLatMedOffset = humeralImplantData => {
  const offset = humeralImplantData?.AnatomicImplantLatMedOffset;
  return getDistance(offset, Math.abs(evenRound(offset * 100, 1)));
};

export const getAnatomicLatMedLabel = humeralImplantData => {
  const offset = humeralImplantData?.AnatomicImplantLatMedOffset;
  return offset > 0 ? latMedValues.LATERALIZATION : latMedValues.MEDIALIZATION;
};

export const getAnatomicPostAntOffset = humeralImplantData => {
  const offset = humeralImplantData?.AnatomicImplantPostAntOffset;
  return getDistance(offset, Math.abs(evenRound(offset * 100, 1)));
};

export const getAnatomicPostAntLabel = (
  humeralImplantData,
  operationSideValue
) => {
  const offset = humeralImplantData?.AnatomicImplantPostAntOffset;

  return getAntPostLabel(offset, operationSideValue);
};

export const measurementsIncludesHumeralPoints = surgeryMeasurements => {
  return (
    dataIncludesHumeralPoints(surgeryMeasurements?.segmentationData) ||
    dataIncludesHumeralPoints(surgeryMeasurements?.surgeonData)
  );
};

export const dataIncludesHumeralPoints = data => {
  const pointsArray = [
    data?.centralPoint,
    data?.frontalPoint,
    data?.shaftMaxPoint,
    data?.shaftMinPoint
  ];

  return checkValidPointsData(pointsArray);
};

export const getAnatomicResectionPlaneFullRotation = (
  humeralImplantData,
  operationSide
) => {
  let anatomicResectionPlaneFullRotation =
    humeralImplantData?.AnatomicResectionPlaneFullRotation;

  if (operationSide === parsedOperationSideValues.LEFT) {
    anatomicResectionPlaneFullRotation =
      anatomicResectionPlaneFullRotation * -1;
  }

  return getDegrees(evenRound(anatomicResectionPlaneFullRotation));
};

export const getReverseResectionPlaneFullRotation = (
  humeralImplantData,
  operationSide
) => {
  let reverseResectionPlaneFullRotation =
    humeralImplantData?.ReverseResectionPlaneFullRotation;

  if (operationSide === parsedOperationSideValues.LEFT) {
    reverseResectionPlaneFullRotation = reverseResectionPlaneFullRotation * -1;
  }

  return getDegrees(evenRound(reverseResectionPlaneFullRotation));
};

export const getReverseResectionInclinationData = humeralImplantData => {
  const implantTranslation =
    humeralImplantData?.ReverseResectionPlaneInclination;

  return getDegrees(
    implantTranslation,
    Math.abs(evenRound(132.5 + implantTranslation, 1))
  );
};

export const getHumeralPatientAnatomyValues = (
  surgeryPlanning,
  surgeryMeasurements,
  operationSideValue
) => {
  return compact([
    {
      value: surgeryPlanning.HumeralPointsDifferFromDefaults
        ? MEASUREMENTS_LABEL.SURGEON_MODIFIED
        : MEASUREMENTS_LABEL.AUTOMATED,
      attribute: HUMERAL_PATIENT_ANATOMY_LABELS.MEASUREMENTS
    },
    {
      value: getHumeralInclinationValue(),
      attribute: HUMERAL_PATIENT_ANATOMY_LABELS.INCLINATION
    },
    {
      value: getHumeralVersionValue(surgeryMeasurements, operationSideValue),
      attribute: HUMERAL_PATIENT_ANATOMY_LABELS.HEAD_CUT_PLANE_ROTATION
    },
    {
      value: getHumeralHeadDiameterData(surgeryMeasurements),
      attribute: HUMERAL_PATIENT_ANATOMY_LABELS.HUMERAL_HEAD_DIAMETER,
      basisType: "secondary"
    }
  ]);
};

export const getHumeralSurgeryPropertiesValues = (
  isReverse,
  surgeryPlanning,
  operationSideValue
) => {
  const humeralImplantData = surgeryPlanning.HumeralImplantData;
  const propertiesValues = [
    {
      value: getHeadCutDiameterData(humeralImplantData),
      attribute: HUMERAL_SURGERY_PROPERTIES_LABELS.HEAD_CUT_DIAMETER
    }
  ];
  return compact(
    isReverse
      ? [
          ...propertiesValues,
          {
            value: getReverseResectionInclinationData(
              surgeryPlanning.HumeralImplantData
            ),
            attribute:
              HUMERAL_SURGERY_PROPERTIES_LABELS.HEAD_CUT_PLANE_INCLINATION
          },
          {
            value: getReverseResectionPlaneFullRotation(
              surgeryPlanning.HumeralImplantData,
              operationSideValue
            ),
            attribute: HUMERAL_SURGERY_PROPERTIES_LABELS.HEAD_CUT_PLANE_ROTATION
          },
          {
            title: "Implant translation",
            value: getReversePostAntOffset(humeralImplantData),
            attribute: getReversePostAntLabel(
              humeralImplantData,
              operationSideValue
            )
          },
          {
            value: getReverseLatMedOffset(humeralImplantData),
            attribute: getReverseLatMedLabel(humeralImplantData),
            basisType: "secondary"
          }
        ]
      : [
          ...propertiesValues,
          {
            value: getAnatomicResectionInclinationData(
              surgeryPlanning.HumeralImplantData
            ),
            attribute:
              HUMERAL_SURGERY_PROPERTIES_LABELS.HEAD_CUT_PLANE_INCLINATION
          },
          {
            value: getAnatomicResectionPlaneFullRotation(
              surgeryPlanning.HumeralImplantData,
              operationSideValue
            ),
            attribute: HUMERAL_SURGERY_PROPERTIES_LABELS.HEAD_CUT_PLANE_ROTATION
          },
          {
            value: getAnatomicHeadRotationData(
              surgeryPlanning.HumeralImplantData
            ),
            attribute: HUMERAL_SURGERY_PROPERTIES_LABELS.HEAD_ROTATION
          },
          {
            title: "Implant translation",
            value: getAnatomicPostAntOffset(humeralImplantData),
            attribute: getAnatomicPostAntLabel(
              humeralImplantData,
              operationSideValue
            )
          },
          {
            value: getAnatomicLatMedOffset(humeralImplantData),
            attribute: getAnatomicLatMedLabel(humeralImplantData)
          }
        ]
  );
};

export const getScapularPropertiesValues = (
  procedure,
  implantsInfo,
  operationSideValue,
  surgeryPlanning
) => {
  const isReverse = procedure;
  const implantData = surgeryPlanning.ImplantData;

  const selectedScapularAnatomicImplant = getSelectedScapularAnatomicImplant(
    implantData.ImplantCode,
    implantsInfo
  );

  return compact([
    isReverse
      ? null
      : showImplantType(
          surgeryPlanning,
          selectedScapularAnatomicImplant,
          implantsInfo
        ),
    isReverse
      ? null
      : {
          value: getGlenoidDiameter(
            selectedScapularAnatomicImplant,
            implantsInfo
          ),
          attribute: "Diameter"
        },
    isReverse
      ? null
      : {
          value: getGlenoidThickness(
            selectedScapularAnatomicImplant,
            implantsInfo
          ),
          attribute: "Thickness"
        },
    {
      value: getDegrees(
        Math.abs(
          bankersRound(
            isReverse
              ? implantData.SecondaryInclination
              : implantData.Inclination
          )
        )
      ),
      attribute:
        "Pocket " +
        parseInclination(
          isReverse ? implantData.SecondaryInclination : implantData.Inclination
        )
    },
    {
      value: getDegrees(
        Math.abs(
          bankersRound(
            isReverse ? implantData.SecondaryVersion : implantData.Version,
            "version"
          )
        )
      ),
      attribute:
        "Pocket " +
        parseVersion(
          bankersRound(
            isReverse ? implantData.SecondaryVersion : implantData.Version
          ),
          operationSideValue
        )
    },
    {
      value: getDegrees(parseRotation(implantData.Rotation)),
      attribute: "Rotation"
    },
    isReverse
      ? null
      : {
          value: getDegrees(
            Math.abs(bankersRound(implantData.SecondaryInclination))
          ),
          attribute:
            "Face " + parseInclination(implantData.SecondaryInclination)
        },
    isReverse
      ? null
      : {
          value: getDegrees(
            Math.abs(bankersRound(implantData.SecondaryVersion))
          ),
          attribute:
            "Face " +
            parseVersion(
              bankersRound(implantData.SecondaryVersion),
              operationSideValue
            )
        },
    isReverse
      ? {
          value: getDegrees(
            implantData.Inclination,
            Math.abs(bankersRound(implantData.Inclination))
          ),
          attribute: "Gleno " + parseInclination(implantData.Inclination)
        }
      : null,
    isReverse
      ? {
          value: getDegrees(
            implantData.Version,
            Math.abs(bankersRound(implantData.Version))
          ),
          attribute:
            "Gleno " +
            parseVersion(bankersRound(implantData.Version), operationSideValue)
        }
      : null,
    {
      value: parseMedLat(implantData.Lateralization),
      attribute: getMedLat(implantData.Lateralization)
    },
    isReverse ? null : {}
  ]);
};

export const getScapularPatientAnatomyValues = (
  surgeryPlanning,
  patientProfileData,
  operationSideValue
) => {
  return compact([
    {
      value: surgeryPlanning.ScapularPointsDifferFromDefaults
        ? MEASUREMENTS_LABEL.SURGEON_MODIFIED
        : MEASUREMENTS_LABEL.AUTOMATED,
      attribute: "Measurements"
    },
    {
      value: parseGlenoidPatientAnatomy(patientProfileData.GlenoidInclination),
      attribute:
        patientProfileData.GlenoidInclination >= 0
          ? "Glenoid inc. sup"
          : "Glenoid inc. inf"
    },
    {
      value: parseGlenoidPatientAnatomy(patientProfileData.GlenoidVersion),
      attribute: parseGlenoidVersion(
        patientProfileData.GlenoidVersion,
        operationSideValue
      )
    },
    {
      value: parseHumerusSubluxation(patientProfileData.HumerusSubluxation),
      attribute: "Subluxation"
    }
  ]);
};

export const getScapularImplantSupportValues = implantData => {
  return compact([
    {
      value: `${parseRim(implantData.RimFullSeating)}`,
      attribute: "Rim >= 2mm"
    },
    {
      value: `${parseRim(implantData.RimPartialSeating)}`,
      attribute: "Rim 0.1 to 2mm"
    },
    {
      value: getNoRimInset(
        implantData.RimFullSeating,
        implantData.RimPartialSeating
      ),
      attribute: "No Rim inset"
    },
    {
      value: `${getNoSeating(implantData.Seating)}`,
      attribute: "No seating"
    },
    {
      value: `${parseSeating(implantData.Seating)}`,
      attribute: "Backside support",
      basisType: "secondary"
    }
  ]);
};

const calcConstructValue = originalVal => {
  return Math.trunc(originalVal * 10000) / 10000;
};

const getGlobalLateralizationValue = latMedValue => {
  const calculatedLatMedValue = calcConstructValue(latMedValue);

  return getDistance(calculatedLatMedValue,
    Math.abs(evenRound(calculatedLatMedValue * 100, 0)));
};

const getGlobalLateralizationLabel = latMedValue => {
  return latMedValue < 0 ? "Lateralization" : "Medialization";
};

const getDistalizationValue = proxDistValue => {
  const calculatedProxDistValue = calcConstructValue(proxDistValue);

  return getDistance(
    calculatedProxDistValue,
    Math.abs(evenRound(calculatedProxDistValue * 100, 0))
  );
};

const getDistalizationLabel = proxDistValue => {
  return proxDistValue < 0 ? "Distalization" : "Proximalization";
};

const getAnteriorizationValue = postAntValue => {
  const calculatedPostAntValue = calcConstructValue(postAntValue);

  return getDistance(calculatedPostAntValue,
    Math.abs(evenRound(calculatedPostAntValue * 100, 0)));
};

const getAnteriorizationLabel = (postAntValue, operationSide) => {
  if (operationSide === parsedOperationSideValues.RIGHT) {
    return postAntValue < 0 ? "Posterior" : "Anterior";
  } else {
    return postAntValue > 0 ? "Posterior" : "Anterior";
  }
};

export const getGlenoidCorrectionInclinationLabel = inclination => {
  let res;

  if (inclination < 0) {
    res = "Incl Sup";
  } else if (inclination > 0) {
    res = "Incl Inf";
  } else {
    res = "Inclination";
  }

  return res;
};

export const getGlenoidCorrectionVersionLabel = (version, operationSide) => {
  let res;

  if (operationSide === parsedOperationSideValues.RIGHT) {
    if (version < 0) {
      res = "Anteversion";
    } else if (version > 0) {
      res = "Retroversion";
    } else {
      res = "Version";
    }
  } else {
    if (version > 0) {
      res = "Anteversion";
    } else if (version < 0) {
      res = "Retroversion";
    } else {
      res = "Version";
    }
  }

  return res;
};

export const getGlenoidCorrectionVersionValue = version => {
  return getDegrees(version, Math.abs(evenRound(version)), 0);
};

export const getGlenoidCorrectionInclinationValue = inclination => {
  return getDegrees(inclination, Math.abs(evenRound(inclination)), 0);
};

export const getViewConstructGlenoidCorrectionValues = (
  implantData,
  operationSide
) => {
  return compact([
    {
      value: getGlenoidCorrectionVersionValue(implantData.SecondaryVersion),
      attribute: getGlenoidCorrectionVersionLabel(
        implantData.SecondaryVersion,
        operationSide
      )
    },
    {
      value: getGlenoidCorrectionInclinationValue(
        implantData.SecondaryInclination
      ),
      attribute: getGlenoidCorrectionInclinationLabel(
        implantData.SecondaryInclination
      ),
      basisType: "secondary"
    }
  ]);
};

export const getViewConstructArmCorrectionValues = (
  viewConstructData,
  operationSide
) => {
  const latMedValue = viewConstructData.LatMedValue;
  const proxDistValue = viewConstructData.ProxDistValue;
  const postAntValue = viewConstructData.PostAntValue;
  return compact([
    {
      value: getGlobalLateralizationValue(latMedValue),
      attribute: getGlobalLateralizationLabel(latMedValue)
    },
    {
      value: getAnteriorizationValue(postAntValue),
      attribute: getAnteriorizationLabel(postAntValue, operationSide),
      basisType: "secondary"
    },
    {
      value: getDistalizationValue(proxDistValue),
      attribute: getDistalizationLabel(proxDistValue),
      basisType: "primary"
    }
  ]);
};

export const TAB_TYPES = {
  SCAPULA: "scapula",
  HUMERUS: "humerus",
  CONSTRUCT: "construct"
};

export const getTabTooltipMessage = (
  isDisabled,
  tabType,
  disabledType = "unavailable"
) => {
  const MESSAGES = {
    [TAB_TYPES.HUMERUS]: {
      unavailable:
        "This case does not support humeral planning. Please re-create the case with the latest version available or contact support."
    },
    [TAB_TYPES.CONSTRUCT]: {
      disabled:
        "Please go to View Construct in ProVoyance Shoulder to enable it.",
      unavailable:
        "Construct information is not available for this case. Both bones need to be supported in order to view Construct."
    }
  };
  return isDisabled ? MESSAGES[tabType][disabledType] : "";
};
