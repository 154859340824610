import React from "react";
import PropTypes from "prop-types";
import "./PlanningResult.less";

const basisTypes = {
  primary: "basis-primary",
  secondary: "basis-secondary",
  default: ""
};

const getHeaderClass = isHeader => (isHeader ? " header" : "");
const getTitleFitClass = hasTitle => (hasTitle ? " title-fit" : "");
const getDisclaimerClass = hasDisclaimer =>
  `${hasDisclaimer ? " has-disclaimer" : ""}`;
const getPaddingClass = noPadding =>
  `planning-result-container${noPadding ? " no-padding" : ""}`;

const PlanningResult = ({
  planningResultInfo,
  hasSeparator = false,
  noPadding = false,
  isHeader = false
}) => {
  return (
    <div className={getPaddingClass(noPadding)}>
      {planningResultInfo.map((resultInfo, key) => (
        <React.Fragment key={key}>
          {resultInfo.title && (
            <div
              className={`planning-result-title${getDisclaimerClass(
                resultInfo.disclaimer
              )}`}
            >
              {resultInfo.title}
            </div>
          )}
          {resultInfo.disclaimer && (
            <div className="planning-result-disclaimer">
              {resultInfo.disclaimer}
            </div>
          )}
          <div className="planning-result">
            {resultInfo.values.map((resultInfoValue, resultKey) => (
              <div
                className={`planning-result-detail ${
                  basisTypes[resultInfoValue.basisType || "default"]
                }${isHeader && resultKey === 1 ? " header-second-column" : ""}`}
                key={resultKey}
              >
                {resultInfoValue.title && (
                  <div className={`attribute attribute-title`}>
                    {resultInfoValue.title}
                  </div>
                )}
                <div
                  className={`value${getHeaderClass(
                    isHeader
                  )}${getTitleFitClass(resultInfoValue.title)}`}
                >
                  {resultInfoValue.value}
                </div>
                <div
                  className={`attribute${getDisclaimerClass(
                    resultInfoValue.disclaimer
                  )}${getTitleFitClass(resultInfoValue.title)}`}
                >
                  {resultInfoValue.attribute}
                </div>
                {resultInfoValue.disclaimer && (
                  <div className="disclaimer">{`${resultInfoValue.disclaimer}`}</div>
                )}
              </div>
            ))}
          </div>
        </React.Fragment>
      ))}
      {hasSeparator && <hr className="planning-result-separator" />}
    </div>
  );
};

PlanningResult.propTypes = {
  planningResultInfo: PropTypes.array,
  hasSeparator: PropTypes.bool,
  noPadding: PropTypes.bool,
  isHeader: PropTypes.bool
};

export default React.memo(PlanningResult);
