import React from "react";
import BaseSpinner from "../../common/baseComponents/BaseSpinner";
import InfoCard from "../../infoCard/InfoCard";

const PlanningSpinner = () => {
  return (
    <div className="launch-component planning-info">
      <InfoCard expand isLast>
        <div className="card-content spinner">
          <BaseSpinner />
        </div>
      </InfoCard>
    </div>
  );
};

export default PlanningSpinner;
