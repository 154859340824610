import React from "react";
import PropTypes from "prop-types";
import { Modal } from "antd";
import { buttonConsts } from "./baseComponentTypes";
import BaseButton from "./BaseButton";
import "./BaseModal.less";

const BaseModal = ({
  handleOk = () => undefined,
  handleCancel = () => undefined,
  config = {
    visible: true,
    title: "Dialog Header",
    footer: [
      {
        config: {
          type: buttonConsts.TYPE.LINK,
          size: buttonConsts.SIZE.DEFAULT
        },
        onClick: handleCancel,
        text: "Cancel"
      },
      {
        config: {
          type: buttonConsts.TYPE.PRIMARY,
          size: buttonConsts.SIZE.DEFAULT
        },
        onClick: handleOk,
        text: "OK"
      }
    ]
  },
  children
}) => {
  const buttons = config.footer.map(button => {
    return (
      <BaseButton
        key={button.text}
        config={button.config}
        onClick={button.onClick}
        text={button.text}
      />
    );
  });

  return (
    <Modal
      width={config?.width ? config.width : "25rem"}
      visible={config ? config.visible : true}
      title={config ? config.title : true}
      className={config ? config.className : null}
      maskClosable={config ? config.maskClosable : true}
      zIndex={config ? config.zIndex : 1000}
      closable={config ? config.closable : true}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={buttons}
      centered={true}
    >
      {children}
    </Modal>
  );
};

BaseModal.propTypes = {
  config: PropTypes.object,
  handleOk: PropTypes.func,
  handleCancel: PropTypes.func,
  children: PropTypes.element
};

export default BaseModal;
