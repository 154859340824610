import React from "react";
import PropTypes from "prop-types";
import "./BaseInfoDetail.less";

const BaseInfoDetail = ({ title, data }) => {
  return (
    <div className="base-info-container">
      <div className="base-info-title">{title}</div>
      <div className="base-info-data">{data}</div>
    </div>
  );
};

BaseInfoDetail.propTypes = {
  title: PropTypes.string,
  data: PropTypes.string
};

export default BaseInfoDetail;
