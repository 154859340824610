import axios from "axios";
import userService from "./userService";
import { addNewCaseFilesToBody } from "./common";
import { CASE_TASKS_PIPELINES, PAGINATION_SIZE } from "./consts";
import {
  sortByFactory,
  paramFactory,
  pathFactory,
  urlWithParamsFactory
} from "../library/url";

const KEYCLOAK_RESOURCE = {
  REALMS: "realms",
  AUTH: "auth",
  ADMIN: "admin",
  REALM: "realm",
  USERS: "users",
  COUNT: "count",
  ROLES: "roles",
  ACCOUNT: "account",
  PROTOCOL: "protocol",
  OPEN_ID_CONNECT: "openid-connect",
  ROLE_MAPPINGS: "role-mappings",
  SEND_EMAIL: "execute-actions-email",
  LIFE_SPAN: "lifespan",
  BACKOFFICE: "backoffice",
  REFRESH: "refresh",
  LOGOUT: "logout",
  PARAM_REDIRECT_URI: "redirect_uri",
  PARAM_CLIENT_ID: "client_id",
  PARAM_RESPONSE_TYPE: "response_type",
  CURRENT_SESSION: "sessions?current=true"
};

export const API_RESOURCE = {
  SERVICES: "services",
  ENTITIES: "entities",
  CASES: "surgerycase",
  SUPPORT: "support",
  TICKETS: "case-ticket",
  INVITATION: "case-invitation",
  TASKS: "tasks",
  CLONE_CASE: "clone-case",
  SHARE_CASE: "case-share",
  PLANNING: "surgeryplanning",
  CASENUMBER: "caseNumber",
  PARAM_START_INDEX: "startIndex",
  PARAM_PAGE_SIZE: "pageSize",
  PARAM_SORT_BY: "sortBy",
  PARAM_SORT_BY_ORDER_DESC: "desc",
  PARAM_SORT_BY_ORDER_ASC: "asc",
  PARAM_SORT_BY_CREATED_DATE: "createdDate",
  PARAM_SORT_BY_ID: "id",
  PARAM_EXPAND: "expandChildren",
  USER: "user",
  QUEUE: "queue",
  SESSION: "session",
  IMPLANTS: "implantsconfiguration",
  NEWS: "news",
  APP: "app",
  RELEASES: "releases"
};

const PROVOYANCE_APP = "provoyance-shoulder";

const JENKINS_RESOURCE = {
  JENKINS_BUILD: "buildWithParameters?delay=0sec",
  BUILD: "build"
};

export const BODY_PARSER = {
  UNDEFINED: "UNDEFINED",
  JSON: "JSON",
  QUERY_STRING: "QUERY_STRING"
};

export const CODE = "code";
export const ITEMS_COUNT_PARAM = "count";
export const EXPAND_PARAM = "expandChildren=true";
export const START_INDEX_PARAM = "?startIndex=";
export const ISSUE_ID_PARAM = "issueId";
export const PAGE_SIZE_PARAM = "&pageSize=";
export const JSON_CONTENT_TYPE = "application/json";
export const MULTIPART_FORM_DATA = "multipart/form-data";
export const FORM_ENCODED_CONTENT_TYPE = "application/x-www-form-urlencoded";

const getKeycloakBaseRealmsUrl = () => {
  return `${process.env.REACT_APP_KEYCLOAK_URL}${pathFactory(
    KEYCLOAK_RESOURCE.REALMS
  )}`;
};

const getKeycloakUrl = () => {
  return pathFactory(
    getKeycloakBaseRealmsUrl(),
    process.env.REACT_APP_KEYCLOAK_REALM
  );
};

export const getShareCaseUrl = () => {
  return pathFactory(
    process.env.REACT_APP_SURGERY_ADJACENT_URL,
    API_RESOURCE.SHARE_CASE
  );
};

export const getShareCaseInfoUrl = uuid => {
  let url = pathFactory(
    process.env.REACT_APP_SURGERY_ADJACENT_URL,
    API_RESOURCE.INVITATION
  );

  if (uuid) {
    url = pathFactory(url, uuid);
  }

  return url;
};

export const getSharedCaseInfoUrl = caseNumber => {
  return pathFactory(
    process.env.REACT_APP_SURGERY_ADJACENT_URL,
    API_RESOURCE.INVITATION,
    API_RESOURCE.CASENUMBER,
    caseNumber
  );
};

export const getSharedCaseListUrl = () => {
  return urlWithParamsFactory(
    pathFactory(
      process.env.REACT_APP_SURGERY_ADJACENT_URL,
      API_RESOURCE.INVITATION
    ),
    paramFactory([{ param: "status", value: "SHARED" }])
  );
};

const getKeycloakAdminUrl = () => {
  return `${process.env.REACT_APP_KEYCLOAK_URL}${pathFactory(
    KEYCLOAK_RESOURCE.ADMIN,
    KEYCLOAK_RESOURCE.REALMS,
    process.env.REACT_APP_KEYCLOAK_REALM
  )}`;
};

export const getBoBackendUrl = () => {
  return pathFactory(
    process.env.REACT_APP_SSO_AUTHENTICATOR_URL,
    KEYCLOAK_RESOURCE.BACKOFFICE
  );
};

export const getBoRefreshTokenUrl = () => {
  return pathFactory(getBoBackendUrl(), KEYCLOAK_RESOURCE.REFRESH);
};

export const getBoBackendLogoutUrl = () => {
  return pathFactory(getBoBackendUrl(), KEYCLOAK_RESOURCE.LOGOUT);
};

export const getSessionsUrl = () => {
  return pathFactory(
    getKeycloakUrl(),
    KEYCLOAK_RESOURCE.ACCOUNT,
    KEYCLOAK_RESOURCE.CURRENT_SESSION
  );
};

export const getUsersUrl = () => {
  return pathFactory(getKeycloakAdminUrl(), KEYCLOAK_RESOURCE.USERS);
};

export const getUserListUrl = (role, userCount = null) => {
  const params = [{ param: "first", value: 0 }];

  if (userCount) {
    params.push({ param: "max", value: userCount });
  }

  const paramString = paramFactory(params);

  return urlWithParamsFactory(
    pathFactory(getRolesUrl(role), KEYCLOAK_RESOURCE.USERS),
    paramString
  );
};

export const getUserCountUrl = () => {
  return pathFactory(getUsersUrl(), KEYCLOAK_RESOURCE.COUNT);
};

export const getRolesUrl = (roleName = null) => {
  const rolesUrl = pathFactory(getKeycloakAdminUrl(), KEYCLOAK_RESOURCE.ROLES);
  if (roleName) {
    return rolesUrl.concat(`/${roleName}`);
  }
  return rolesUrl;
};

export const getNotificationEmitterSusbscribeUrl = () => {
  return pathFactory(
    "",
    API_RESOURCE.USER,
    API_RESOURCE.QUEUE,
    API_RESOURCE.SESSION
  );
};

export const getNotificationEmitterUrl = () => {
  return process.env.REACT_APP_NOTIFICATION_EMITTER_URL;
};

export const getRoleMappingsUrl = userId => {
  return pathFactory(
    getUsersUrl(),
    userId,
    KEYCLOAK_RESOURCE.ROLE_MAPPINGS,
    KEYCLOAK_RESOURCE.REALM
  );
};

export const getSendEmailUrl = userId => {
  const params = [
    {
      param: KEYCLOAK_RESOURCE.LIFE_SPAN,
      value: process.env.REACT_APP_PASSWORD_RESET_TIMEOUT ?? 259200
    },
    {
      param: KEYCLOAK_RESOURCE.PARAM_REDIRECT_URI,
      value: process.env.REACT_APP_PORTAL_URL
    },
    {
      param: KEYCLOAK_RESOURCE.PARAM_CLIENT_ID,
      value: process.env.REACT_APP_PORTAL_CLIENT_ID
    }
  ];
  const paramString = paramFactory(params);

  return urlWithParamsFactory(
    pathFactory(getUsersUrl(), userId, KEYCLOAK_RESOURCE.SEND_EMAIL),
    paramString
  );
};

export const getSupportTicketsUrl = link => {
  return pathFactory(
    process.env.REACT_APP_SURGERY_CORE_URL,
    link || getTicketsEndpoint()
  );
};

export const getTicketsEndpoint = () => {
  const params = [
    {
      param: API_RESOURCE.PARAM_PAGE_SIZE,
      value: PAGINATION_SIZE
    }
  ];

  const url = pathFactory(
    API_RESOURCE.SERVICES,
    API_RESOURCE.SUPPORT,
    API_RESOURCE.TICKETS
  );

  return `${urlWithParamsFactory(
    url,
    paramFactory(params)
  )}${getTicketsDefaultSortBy()}`;
};

export const getTaskUrl = (taskId = null) => {
  const rolesUrl = pathFactory(
    process.env.REACT_APP_SURGERY_CORE_URL,
    API_RESOURCE.SERVICES,
    API_RESOURCE.SUPPORT,
    API_RESOURCE.TASKS
  );
  if (taskId) {
    return rolesUrl.concat(`/${taskId}`);
  }
  return rolesUrl;
};

export const getTasksUrlByIssueParam = issueId => {
  const url = pathFactory(
    process.env.REACT_APP_SURGERY_CORE_URL,
    API_RESOURCE.SERVICES,
    API_RESOURCE.SUPPORT,
    API_RESOURCE.TASKS
  );

  const params = [
    {
      param: ISSUE_ID_PARAM,
      value: issueId
    }
  ];

  return urlWithParamsFactory(url, paramFactory(params));
};

export const getTicketsDefaultSortBy = () => {
  const sortParams = {
    param: API_RESOURCE.PARAM_SORT_BY_CREATED_DATE,
    value: API_RESOURCE.PARAM_SORT_BY_ORDER_DESC
  };

  const params = [
    {
      param: API_RESOURCE.PARAM_SORT_BY,
      value: sortByFactory(sortParams)
    }
  ];
  return `&${paramFactory(params)}`;
};

export const getPipelineUrl = pipelineName => {
  return pathFactory(
    process.env.REACT_APP_JENKINS_URL,
    process.env.REACT_APP_SUPPORT_JOB_ENDPOINT,
    pipelineName
  );
};

export const getPipelineBuildUrl = pipelineName => {
  return pathFactory(
    getPipelineUrl(pipelineName),
    JENKINS_RESOURCE.JENKINS_BUILD
  );
};

export const getNewsUrl = id => {
  if (id) {
    return pathFactory(
      process.env.REACT_APP_SURGERY_ADJACENT_URL,
      API_RESOURCE.ENTITIES,
      API_RESOURCE.NEWS,
      id
    );
  } else {
    return pathFactory(
      process.env.REACT_APP_SURGERY_ADJACENT_URL,
      API_RESOURCE.ENTITIES,
      API_RESOURCE.NEWS
    );
  }
};

export const getNotificationsUrl = id => {
  if (id) {
    return pathFactory(
      process.env.REACT_APP_SURGERY_ADJACENT_URL,
      API_RESOURCE.ENTITIES,
      API_RESOURCE.APP,
      PROVOYANCE_APP,
      API_RESOURCE.RELEASES,
      id
    );
  } else {
    return pathFactory(
      process.env.REACT_APP_SURGERY_ADJACENT_URL,
      API_RESOURCE.ENTITIES,
      API_RESOURCE.APP,
      PROVOYANCE_APP,
      API_RESOURCE.RELEASES
    );
  }
};

export const getBuildUrl = url => {
  return pathFactory(url, JENKINS_RESOURCE.BUILD);
};

export const getCaseUrl = caseId => {
  const url = pathFactory(
    process.env.REACT_APP_SURGERY_CORE_URL,
    API_RESOURCE.SERVICES,
    API_RESOURCE.ENTITIES,
    API_RESOURCE.CASES,
    caseId
  );

  return urlWithParamsFactory(url, EXPAND_PARAM);
};

export const getCasesDefaultSortBy = () => {
  const sortParams = {
    param: API_RESOURCE.PARAM_SORT_BY_ID,
    value: API_RESOURCE.PARAM_SORT_BY_ORDER_DESC
  };

  const params = [
    {
      param: API_RESOURCE.PARAM_EXPAND,
      value: true
    },
    {
      param: API_RESOURCE.PARAM_SORT_BY,
      value: sortByFactory(sortParams)
    }
  ];
  return `${paramFactory(params)}`;
};

export const getCasesEndpoint = () => {
  const url = pathFactory(
    API_RESOURCE.SERVICES,
    API_RESOURCE.ENTITIES,
    API_RESOURCE.CASES
  );

  return urlWithParamsFactory(url, getCasesDefaultSortBy());
};

export const getSurgeryCaseUrl = link => {
  return pathFactory(
    process.env.REACT_APP_SURGERY_CORE_URL,
    link || getCasesEndpoint()
  );
};

export const getCloneCaseUrl = () => {
  return pathFactory(
    process.env.REACT_APP_SURGERY_CORE_URL,
    API_RESOURCE.SERVICES,
    API_RESOURCE.ENTITIES,
    API_RESOURCE.CLONE_CASE
  );
};

export const getPlanningUrl = planningId => {
  return pathFactory(
    process.env.REACT_APP_SURGERY_CORE_URL,
    API_RESOURCE.SERVICES,
    API_RESOURCE.ENTITIES,
    API_RESOURCE.PLANNING,
    planningId
  );
};

export const getKeycloakLoginPageUrl = (redirectUrl = "") => {
  const url = pathFactory(
    process.env.REACT_APP_KEYCLOAK_URL,
    KEYCLOAK_RESOURCE.REALMS,
    process.env.REACT_APP_KEYCLOAK_REALM,
    KEYCLOAK_RESOURCE.PROTOCOL,
    KEYCLOAK_RESOURCE.OPEN_ID_CONNECT,
    KEYCLOAK_RESOURCE.AUTH
  );

  const params = [
    {
      param: KEYCLOAK_RESOURCE.PARAM_CLIENT_ID,
      value: process.env.REACT_APP_KEYCLOAK_CLIENT_ID
    },
    { param: KEYCLOAK_RESOURCE.PARAM_RESPONSE_TYPE, value: CODE },
    { param: KEYCLOAK_RESOURCE.PARAM_REDIRECT_URI, value: redirectUrl }
  ];

  return urlWithParamsFactory(url, paramFactory(params));
};

export const getKeycloakPortalLoginUrl = (redirectPath = "") => {
  const redirectUrl = pathFactory(
    process.env.REACT_APP_PORTAL_URL,
    redirectPath
  );

  return getKeycloakLoginPageUrl(redirectUrl);
};

export const getCaseByParametersUrl = parameters => {
  const params = [
    {
      param: API_RESOURCE.PARAM_EXPAND,
      value: true
    },
    ...parameters
  ];

  const url = pathFactory(
    process.env.REACT_APP_SURGERY_CORE_URL,
    API_RESOURCE.SERVICES,
    API_RESOURCE.ENTITIES,
    API_RESOURCE.CASES
  );

  return urlWithParamsFactory(url, paramFactory(params));
};

export const getTotalCasesUrl = () => {
  return pathFactory(
    process.env.REACT_APP_SURGERY_CORE_URL,
    API_RESOURCE.SERVICES,
    API_RESOURCE.ENTITIES,
    API_RESOURCE.CASES,
    ITEMS_COUNT_PARAM
  );
};

export const getImplantsInfoUrl = () => {
  return pathFactory(
    process.env.REACT_APP_SURGERY_CORE_URL,
    API_RESOURCE.SERVICES,
    API_RESOURCE.ENTITIES,
    API_RESOURCE.IMPLANTS
  );
};

export const refreshToken = async () => {
  if (userService.isTokenExpired()) {
    await userService.updateToken();
  }
};

const refreshAndRetrieveToken = async () => {
  await refreshToken();
  return userService.getToken();
};

export const sendJenkinsBuildJob = async (
  pipeline,
  body,
  files,
  jenkinsAuth
) => {
  const pipelineUrl = getPipelineBuildUrl(pipeline);
  const auth = Buffer.from(
    `${jenkinsAuth.username}:${jenkinsAuth.apiKey}`,
    "utf-8"
  ).toString("base64");

  let formData = new FormData();
  for (const key in body) {
    formData.append(key, body[key]);
  }
  if (pipeline === CASE_TASKS_PIPELINES.INJECT) {
    addNewCaseFilesToBody(files, formData);
  }

  return axios.post(pipelineUrl, formData, {
    headers: {
      "Content-Type": MULTIPART_FORM_DATA,
      Authorization: `Basic ${auth}`
    }
  });
};

export const sendGetRequest = async (
  url,
  body = null,
  skipCache = false,
  additionalHeaders = {}
) => {
  await refreshToken();
  const baseHeaders = {
    Authorization: "Bearer ".concat(userService.getToken())
  };

  if (skipCache) {
    additionalHeaders["Cache-Control"] = "no-cache";
  }
  const headers = { ...baseHeaders, ...additionalHeaders };

  if (body) {
    return axios.get(url, body, {
      headers: headers
    });
  } else {
    return axios.get(url, {
      headers: headers
    });
  }
};

export const sendPostRequest = async (url, bodyParser, body, contentType) => {
  const token = await refreshAndRetrieveToken();
  switch (bodyParser) {
    case BODY_PARSER.JSON:
      return axios.post(url, JSON.stringify(body), {
        headers: {
          "Content-Type": contentType,
          Authorization: "Bearer ".concat(token)
        }
      });
    case BODY_PARSER.QUERY_STRING:
      return axios.post(url, new URLSearchParams(body), {
        headers: {
          "Content-Type": contentType,
          Authorization: "Bearer ".concat(token)
        }
      });
    case BODY_PARSER.UNDEFINED:
    default:
      return axios.post(url, body, {
        headers: {
          "Content-Type": contentType,
          Authorization: "Bearer ".concat(token)
        }
      });
  }
};

export const sendPutRequest = async (url, bodyParser, body, contentType) => {
  const token = await refreshAndRetrieveToken();
  switch (bodyParser) {
    case BODY_PARSER.JSON:
      return axios.put(url, JSON.stringify(body), {
        headers: {
          "Content-Type": contentType,
          Authorization: "Bearer ".concat(token)
        }
      });
    case BODY_PARSER.QUERY_STRING:
      return axios.put(url, new URLSearchParams(body), {
        headers: {
          "Content-Type": contentType,
          Authorization: "Bearer ".concat(token)
        }
      });
    case BODY_PARSER.UNDEFINED:
    default:
      return axios.put(url, body, {
        headers: {
          "Content-Type": contentType,
          Authorization: "Bearer ".concat(token)
        }
      });
  }
};

export const sendDeleteRequest = async (url, contentType, isLogin = false) => {
  const headers = {
    "Content-Type": contentType
  };
  if (!isLogin) {
    await refreshToken();
    headers.Authorization = "Bearer ".concat(userService.getToken());
  }
  return axios.delete(url, { headers });
};

export const sendPatchRequest = async (url, bodyParser, body, contentType) => {
  const token = await refreshAndRetrieveToken();
  switch (bodyParser) {
    case BODY_PARSER.JSON:
      return axios.patch(url, JSON.stringify(body), {
        headers: {
          "Content-Type": contentType,
          Authorization: "Bearer ".concat(token)
        }
      });
    case BODY_PARSER.QUERY_STRING:
      return axios.patch(url, new URLSearchParams(body), {
        headers: {
          "Content-Type": contentType,
          Authorization: "Bearer ".concat(token)
        }
      });
    case BODY_PARSER.UNDEFINED:
    default:
      return axios.patch(url, body, {
        headers: {
          "Content-Type": contentType,
          Authorization: "Bearer ".concat(token)
        }
      });
  }
};

export const backofficeSentryURL =
  "https://b30f656c58a14e2092c7d9ae5a666bb7@apm.genesisplanningsoftware.com/13";
