import React from "react";
import PropTypes from "prop-types";
import { DatePicker } from "antd";
import moment from "moment";
import { buttonSize } from "../baseComponents/baseComponentTypes";
import { SHORT_DATE_FORMAT } from "../../../helpers/datesHelper";
import "./BaseDatePicker.less";

const defaultConfig = {
  placeholder: null,
  disabledDate: null,
  value: null,
  disabled: false
};

const BaseDatePicker = ({ config = defaultConfig, onChange = null }) => {
  config = { ...defaultConfig, ...config };
  const momentValue = moment(config.value, SHORT_DATE_FORMAT);
  return (
    <DatePicker
      className="input-container"
      onChange={onChange}
      getCalendarContainer={trigger => trigger.parentNode}
      placeholder={config.placeholder}
      disabledDate={config.disabledDate}
      value={momentValue.isValid() ? momentValue : defaultConfig.value}
      size={buttonSize.SMALL}
      disabled={config.disabled}
      format={SHORT_DATE_FORMAT}
    />
  );
};
BaseDatePicker.propTypes = {
  config: PropTypes.object,
  onChange: PropTypes.func
};

export default BaseDatePicker;
