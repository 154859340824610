import React from "react";
import { BaseButton } from "../../common/baseComponents/BaseButton";
import { ReactComponent as LaunchLogo } from "../../../resources/newcase/inset_implant.svg";
import "./PlanningNotAvailable.less";

const PlanningNotAvailable = () => {
  return (
    <div className="launch-component">
      <div className="card-content">
        <div className="expandable"></div>
        <LaunchLogo className="launch-logo" />
        <div>You are ready to start the surgery plan!</div>
        <div>
          <BaseButton
            config={{
              type: "primary",
              size: "small",
              disabled: true
            }}
            text="Launch 3D Planning"
          />
        </div>
        <div className="expandable"></div>
      </div>
    </div>
  );
};

export default PlanningNotAvailable;
