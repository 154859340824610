import React from "react";
import PropTypes from "prop-types";

import "./RowDetail.less";

const RowDetail = ({ label, value }) => {
  return (
    <div className="info-row">
      <div className="property-label">{label}:&nbsp;</div>
      <div className="property-value">{value}</div>
    </div>
  );
};

RowDetail.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default RowDetail;
