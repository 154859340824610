import React from "react";
import PropTypes from "prop-types";
import "./BaseImage.less";

const defaultStyle = { maxWidth: "100%" };

const BaseImage = ({ source, propAlt, propStyle = {}, onClick = null }) => {
  const style = { ...defaultStyle, ...propStyle };
  const base64Prefix = "data:image/jpeg;base64,";
  return (
    <img
      className="base-image"
      src={`${base64Prefix}${source}`}
      alt={propAlt}
      style={style}
      onClick={onClick}
    />
  );
};

BaseImage.propTypes = {
  source: PropTypes.string,
  propAlt: PropTypes.string,
  propStyle: PropTypes.object,
  onClick: PropTypes.func
};

export default BaseImage;
