import Cookies from "js-cookie";
import jwt from "jwt-decode";
import axios from "axios";
import { BO_AUTH_COOKIE_NAME, INACTIVITY_REFRESH } from "./consts";

const getCookie = cookieName => {
  const cookie = Cookies.get(cookieName);
  return cookie ? JSON.parse(cookie) : null;
};

const getCookieField = (cookieName, field) => {
  const cookieContent = getCookie(cookieName);
  return cookieContent[field];
};

const deleteCookie = cookieName => {
  Cookies.remove(cookieName);
};

const getToken = (
  tokenName = "access_token",
  cookieName = BO_AUTH_COOKIE_NAME
) => getCookieField(cookieName, tokenName);

const isTokenExpired = () => {
  const accessToken = getToken();
  const secondsToExpireToken = getSecondsToExpireToken(accessToken);

  return secondsToExpireToken <= 10000;
};

const getRefreshTokenExpiration = () => {
  const refreshToken = getToken("refresh_token");
  const secondsToExpireToken = getSecondsToExpireToken(refreshToken);

  return (
    secondsToExpireToken * INACTIVITY_REFRESH.REFRESH_TOKEN_FORCE_PERCENTAGE
  );
};

const getSecondsToExpireToken = token => {
  const tokenExpiresInEpoch = jwt(token).exp;
  const tokenExpiresIn = new Date(tokenExpiresInEpoch * 1000);
  return tokenExpiresIn - new Date();
};

const updateToken = async url => {
  const { FORM_ENCODED_CONTENT_TYPE } = await import("./URLHelper");

  await axios.get(url, {
    withCredentials: true,
    headers: {
      "Content-Type": FORM_ENCODED_CONTENT_TYPE
    }
  });
};

const getName = () => {
  const accessToken = getToken();

  return jwt(accessToken).name;
};

const getUsername = () => {
  const accessToken = getToken();

  return jwt(accessToken).preferred_username;
};

const getUserId = () => {
  const accessToken = getToken();

  return jwt(accessToken).sub;
};

const getUserRoles = () => {
  const accessToken = getToken();

  return jwt(accessToken).realm_access.roles;
};

const closeSession = async url => {
  await axios.get(url, { withCredentials: true });
};

const getUserEmail = () => {
  const accessToken = getToken();

  return jwt(accessToken).email;
};

const getSessionId = () => {
  const accessToken = getToken();

  return jwt(accessToken).session_state;
};

const userService = {
  getCookie,
  deleteCookie,
  getName,
  getUsername,
  getUserId,
  getUserRoles,
  getToken,
  isTokenExpired,
  closeSession,
  updateToken,
  getRefreshTokenExpiration,
  getUserEmail,
  getSessionId
};

export default userService;
