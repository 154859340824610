import React from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import { BaseButton } from "../../common/baseComponents/BaseButton";
import { buttonConsts } from "./baseComponentTypes";
import BaseCheckBox from "./BaseCheckBox";

const getSelectFilterOptions = (
  setSelectedKeys,
  selectedKeys,
  options,
  selectedDropdownFilters,
  filter
) => {
  const currentFilters = selectedDropdownFilters.current;

  if (isEmpty(selectedKeys)) {
    selectedKeys = currentFilters[filter];
  }

  return Object.values(options).map(option => {
    const isChecked = selectedKeys.indexOf(option.value) !== -1;

    return (
      <BaseCheckBox
        key={option.value}
        config={{
          label: option.text,
          checked: isChecked
        }}
        onChange={value => {
          setSelectedKeys(
            value.target.checked
              ? [...selectedKeys, option.value]
              : selectedKeys.filter(element => element !== option.value)
          );

          currentFilters[filter] = value.target.checked
            ? [...selectedKeys, option.value]
            : selectedKeys.filter(element => element !== option.value);
        }}
      />
    );
  });
};

const SelectSearch = ({
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
  options,
  selectedDropdownFilters,
  filter
}) => (
  <div className="select-search-dropdown">
    {getSelectFilterOptions(
      setSelectedKeys,
      selectedKeys,
      options,
      selectedDropdownFilters,
      filter
    )}

    <div className="buttons-search">
      <BaseButton
        config={{
          type: buttonConsts.TYPE.DEFAULT,
          size: buttonConsts.SIZE.SMALL
        }}
        onClick={() => {
          clearFilters();
          selectedDropdownFilters.current[filter] = [];
        }}
        text="Reset"
      />
      <BaseButton
        config={{
          type: buttonConsts.TYPE.PRIMARY,
          size: buttonConsts.SIZE.SMALL
        }}
        onClick={() => {
          setSelectedKeys(selectedDropdownFilters.current[filter]);
          confirm();
        }}
        text="OK"
      />
    </div>
  </div>
);

SelectSearch.propTypes = {
  setSelectedKeys: PropTypes.func,
  selectedKeys: PropTypes.array,
  confirm: PropTypes.func,
  clearFilters: PropTypes.func,
  options: PropTypes.array,
  selectedDropdownFilters: PropTypes.object,
  filter: PropTypes.string
};

export default SelectSearch;
