import moment from "moment";
import { MonthNames, TODAY } from "./consts";

export const dateFormat = {
  SLASH_FORMAT: "SLASH_FORMAT",
  HYPHEN_FORMAT: "HYPHEN_FORMAT"
};

export const dateSeparator = {
  SLASH_SEPARATOR: "/",
  HYPHEN_SEPARATOR: "-"
};

export const DATE_FORMAT = {
  ISO: `MM${dateSeparator.HYPHEN_SEPARATOR}DD${dateSeparator.HYPHEN_SEPARATOR}YY`,
  bigEndian: `YYYY${dateSeparator.HYPHEN_SEPARATOR}MM${dateSeparator.HYPHEN_SEPARATOR}DD`
};

export const SHORT_DATE_FORMAT = `MM${dateSeparator.SLASH_SEPARATOR}DD${dateSeparator.SLASH_SEPARATOR}YYYY`;

export const oneDayMs = 60 * 60 * 24 * 1000;

export const getTodayDate = () => {
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0");
  const yyyy = today.getFullYear();
  return returnFormattedDate(mm, dd, yyyy);
};

export const getDatesDiff = (laterDate, earlierDate) => {
  return Math.floor(
    (Date.parse(laterDate) - Date.parse(earlierDate)) / 86400000
  );
};

export const isToday = dateToCompare => {
  const today = new Date();
  return (
    dateToCompare.getDate() === today.getDate() &&
    dateToCompare.getMonth() === today.getMonth() &&
    dateToCompare.getFullYear() === today.getFullYear()
  );
};

export const getFullStringFromDate = date => {
  return `${date.getDate()} ${
    MonthNames[date.getMonth()]
  } ${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:
  ${date.getSeconds()}`;
};

export const getNamedDate = date => {
  return `${
    MonthNames[date.getMonth()]
  } ${date.getDate()}, ${date.getFullYear()}`;
};

export const formatDate = date => {
  if (!date || typeof date !== "string") {
    return null;
  }
  return new Date(
    date.split(dateSeparator.HYPHEN_SEPARATOR)[0],
    date.split(dateSeparator.HYPHEN_SEPARATOR)[1] - 1,
    date.split(dateSeparator.HYPHEN_SEPARATOR)[2]
  );
};

export const get2DecimalNumber = number => {
  return number > 9 ? "" + number : "0" + number;
};

export const getFormatDateFromDate = date => {
  if (!date) {
    return null;
  }
  return `${date.getFullYear()}-${get2DecimalNumber(
    date.getMonth() + 1
  )}-${get2DecimalNumber(date.getDate())}`;
};

export const calculateAge = birthdate => {
  if (
    !birthdate ||
    Object.prototype.toString.call(birthdate) !== "[object Date]"
  ) {
    return null;
  }
  let diffMs = Date.now() - birthdate.getTime();
  let ageDt = new Date(diffMs);
  return Math.abs(ageDt.getUTCFullYear() - 1970);
};

export const parseDateToFilterFormat = date => {
  if (!date) {
    return null;
  }
  const year = date.getFullYear().toString();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return returnFormattedDate(month, day, year);
};

export const parseDateFormat = date => {
  if (!date || typeof date !== "string") {
    return null;
  }
  return moment(date).format(SHORT_DATE_FORMAT);
};

export const parseDateFormatFullYear = expirationDate => {
  if (!expirationDate || typeof expirationDate !== "string") {
    return null;
  }
  let year = expirationDate.split("-")[0];
  let month = expirationDate.split("-")[1];
  let day = expirationDate.split("-")[2];
  return (
    month +
    dateSeparator.SLASH_SEPARATOR +
    day +
    dateSeparator.SLASH_SEPARATOR +
    year
  );
};

export const getRoundDate = () => {
  const date = new Date();
  date.setHours(0, 0, 0, 0);
  return date;
};

export const parseIsoStringToDate = (isoStringValue, showTime = true) => {
  let newDateFormat = SHORT_DATE_FORMAT;
  if (showTime) {
    newDateFormat = newDateFormat.concat(" hh:mma");
  }
  return moment(isoStringValue).format(newDateFormat);
};

export const getFormatDateFromStringDate = stringDate => {
  if (typeof stringDate !== "string") return null;
  const date = new Date(stringDate);

  return date.toISOString();
};

export const getFormattedDateFromDate = (
  paramDate,
  displayFormat,
  displaySeparator
) => {
  let date = new Date(paramDate.getTime());
  let formattedDate = null;

  switch (displayFormat) {
    case dateFormat.SLASH_FORMAT:
    case dateFormat.HYPHEN_FORMAT:
      formattedDate = getFormatFromDate(date, displaySeparator, true);
      break;
    default:
      break;
  }

  return formattedDate;
};

export const isValidDate = dateToValidate => {
  return moment(dateToValidate).isValid();
};

export const checkPatientBirthdate = patientBirthdate => {
  return moment(patientBirthdate, "YYYY-MM-DD", true).isValid()
    ? patientBirthdate
    : "";
};

const getFormatFromDate = (paramDate, displaySeparator, fullYear) => {
  let slashFormat = null;
  const separator = displaySeparator;

  if (paramDate instanceof Date) {
    let dd = String(paramDate.getDate()).padStart(2, "0");
    let mm = String(paramDate.getMonth() + 1).padStart(2, "0");
    let year = fullYear
      ? paramDate.getFullYear()
      : paramDate.getFullYear().toString().slice(-2);
    slashFormat = `${mm}${separator}${dd}${separator}${year}`;
  }
  return slashFormat;
};

export const dateToStringFormat = dateToParse => {
  const fullDate = new Date(dateToParse);
  let month = "" + (fullDate.getMonth() + 1);
  let day = "" + fullDate.getDate();
  let year = fullDate.getFullYear();

  const hoursMinutes = fullDate.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true
  });
  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return `${[year, month, day].join(
    dateSeparator.HYPHEN_SEPARATOR
  )} ${hoursMinutes}`;
};

export const secondsToHours = seconds => {
  let hoursString = "";
  let h = Math.floor(seconds / 3600);
  seconds %= 3600;
  let m = Math.floor(seconds / 60);
  let s = Math.floor(seconds % 60);

  if (h > 0) {
    hoursString += `${twoDigitNumber(h)}:`;
  }

  if (m > 0) {
    hoursString += `${twoDigitNumber(m)}:`;
  } else if (m === 0 && h > 0) hoursString += `${twoDigitNumber(m)}:`;

  if (s >= 0) {
    hoursString += `${twoDigitNumber(s)}`;
  }
  return hoursString;
};

const twoDigitNumber = number => {
  return ("0" + number).slice(-2);
};

export const getFirstDateOfTheWeek = (
  weekOffset = 0,
  currentDate = new Date()
) => {
  const workingDate = new Date();
  workingDate.setDate(currentDate.getDate());
  return new Date(
    workingDate.setDate(
      currentDate.getDate() + 7 * weekOffset - currentDate.getDay()
    )
  );
};

export const getLastDateOfTheWeek = (currentDate, weekOffset = 0) => {
  const firstDayOfWeekDate = new Date(
    getFirstDateOfTheWeek(weekOffset, currentDate).getTime()
  );
  return new Date(firstDayOfWeekDate.setDate(firstDayOfWeekDate.getDate() + 6));
};

export const returnFormattedDate = (month, day, year) => {
  return (
    month +
    dateSeparator.SLASH_SEPARATOR +
    day +
    dateSeparator.SLASH_SEPARATOR +
    year
  );
};

export const todayFormat = dateToCompare => {
  const today = moment(new Date()).format(SHORT_DATE_FORMAT);

  return dateToCompare === today ? TODAY : dateToCompare;
};

export const showDaysToSurgery = selectedDateValue => {
  const given = moment(selectedDateValue, DATE_FORMAT.bigEndian);
  const currentDate = moment().startOf("day");
  const datesDiff = Math.floor(
    moment.duration(given.diff(currentDate)).asDays()
  );

  if (datesDiff > 1) {
    return datesDiff + " days to surgery";
  } else if (datesDiff < 0) {
    return Math.abs(datesDiff) + " days since surgery";
  } else {
    return datesDiff + " day to surgery";
  }
};

export const setDateFilter = (event, setSelectedKeys, confirm) => {
  const initialDateParsed = event?.[0]?.format(DATE_FORMAT.bigEndian);
  const endDateParsed = event?.[1]?.format(DATE_FORMAT.bigEndian);

  initialDateParsed && endDateParsed
    ? setSelectedKeys([
        parseDateFormat(initialDateParsed) +
          "-" +
          parseDateFormat(endDateParsed)
      ])
    : setSelectedKeys(null);
  confirm();
};

export const getDateFilterValueFromFilterState = (
  columnKey,
  activeFiltersObject
) => {
  let dateFilterValue = [null, null];
  const filterValue = activeFiltersObject?.[columnKey]
    ? activeFiltersObject[columnKey]
    : null;
  if (filterValue) {
    let tmpFilterValue = filterValue[0].split("-");
    dateFilterValue = [
      moment(new Date(tmpFilterValue[0])),
      moment(new Date(tmpFilterValue[1]))
    ];
  }
  return dateFilterValue;
};
