import React, { useEffect } from "react";
import { Tree } from "antd";
import PropTypes from "prop-types";
import QualityTag from "./QualityTag";
import { getQualityTag } from "../../../helpers/caseHelper";
import "./DicomDirectoryTree.less";

export const DicomDirectoryTree = ({ patientsData, changeSelectedItem }) => {
  const { TreeNode, DirectoryTree } = Tree;

  useEffect(() => {
    const patientKey = Object.keys(patientsData)[0];
    const studyKey = Object.keys(patientsData[patientKey].Studies)[0];
    const serieKey = Object.keys(
      patientsData[patientKey].Studies[studyKey].Series
    )[0];
    changeSelectedItem(patientKey, studyKey, serieKey);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onExpand = (key, event) => {
    const { patient, study, eventKey } = event.node.props;
    if (!patient) {
      return changeSelectedItem(eventKey);
    } else {
      if (!study) {
        return changeSelectedItem(patient, eventKey.split("_")[1]);
      }
      return changeSelectedItem(patient, study, eventKey.split("_")[2]);
    }
  };

  const loopPatients = patients =>
    Object.keys(patients).map(patientKey => {
      return (
        <TreeNode key={patientKey} title={patients[patientKey].name}>
          {loopStudies(patientKey, patients[patientKey].Studies)}
        </TreeNode>
      );
    });

  const loopStudies = (patientKey, studies) =>
    Object.keys(studies).map(studyKey => {
      return (
        <TreeNode
          key={patientKey + "_" + studyKey}
          title={studies[studyKey].description}
          patient={patientKey}
        >
          {loopSeries(patientKey, studyKey, studies[studyKey].Series)}
        </TreeNode>
      );
    });

  const loopSeries = (patientKey, studyKey, series) =>
    Object.keys(series).map(serieKey => {
      return (
        <TreeNode
          key={patientKey + "_" + studyKey + "_" + serieKey}
          title={
            <div className="series-value">
              <div className="series-title">
                {series[serieKey].number +
                  (series[serieKey].description
                    ? " - " + series[serieKey].description
                    : "")}
              </div>
              <QualityTag
                content={getQualityTag(series[serieKey].qualityTag)}
              />
            </div>
          }
          patient={patientKey}
          study={studyKey}
          isLeaf
        />
      );
    });

  const getDefaultSelected = () => {
    const patientKey = Object.keys(patientsData)[0];
    const studyKey = Object.keys(patientsData[patientKey].Studies)[0];
    const serieKey = Object.keys(
      patientsData[patientKey].Studies[studyKey].Series
    )[0];
    return [patientKey + "_" + studyKey + "_" + serieKey];
  };

  return (
    <DirectoryTree
      defaultSelectedKeys={getDefaultSelected()}
      defaultExpandAll
      onSelect={onExpand}
    >
      {loopPatients(patientsData)}
    </DirectoryTree>
  );
};

DicomDirectoryTree.propTypes = {
  patientsData: PropTypes.object,
  changeSelectedItem: PropTypes.func
};

export default DicomDirectoryTree;
