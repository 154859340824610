import React from "react";
import PropTypes from "prop-types";
import { Icon as LegacyIcon } from "@ant-design/compatible";
import { Button, Tooltip } from "antd";

import "./BaseButton.less";

export const BaseButton = ({
  config = { type: "primary", size: "default" },
  onClick = null,
  text = ""
}) => {
  const baseButton = (
    <Button
      className={`button-${config.type} button-${config.shape} ${
        config.className || ""
      }`}
      type={config ? config.type : ""}
      shape={config ? config.shape : ""}
      icon={config.icon ? <LegacyIcon type={config.icon} /> : null}
      size={config ? config.size : ""}
      disabled={config ? config.disabled : false}
      htmlType={config?.htmlType}
      onClick={onClick}
    >
      {text}
    </Button>
  );
  if (config?.tooltip) {
    return (
      <Tooltip placement="bottom" title={config.tooltip}>
        {baseButton}
      </Tooltip>
    );
  }
  return baseButton;
};
BaseButton.propTypes = {
  config: PropTypes.object,
  onClick: PropTypes.func,
  text: PropTypes.string
};

export default BaseButton;
