import React from "react";
import PropTypes from "prop-types";
import RowDetail from "./RowDetail";

import "./PatientDetail.less";

const PatientDetail = ({ patientData }) => {
  const { name, birthdate, comments, id, gender } = patientData;
  return (
    <div className="detail-layout info-wrapper">
      <div className="title full-row-item">Patient</div>
      <RowDetail label="Name" value={name} />
      <RowDetail label="ID" value={id} />
      <RowDetail label="Birthdate" value={birthdate} />
      <RowDetail label="Gender" value={gender} />
      <div className="full-row-item">
        <RowDetail label="Comments" value={comments} />
      </div>
    </div>
  );
};

PatientDetail.propTypes = {
  patientData: PropTypes.object
};

export default PatientDetail;
