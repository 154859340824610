import React from "react";
import PropTypes from "prop-types";
import PatientDetail from "./dicomDetailItems/PatientDetail";
import StudyDetail from "./dicomDetailItems/StudyDetail";
import SerieDetail from "./dicomDetailItems/SerieDetail";

import "./DicomDetail.less";

export const DicomDetail = ({
  selectedPatientData,
  selectedStudyData,
  selectedSerieData
}) => {
  return (
    <div className="dicom-detail-layout">
      {selectedPatientData && (
        <PatientDetail patientData={selectedPatientData} />
      )}
      {selectedStudyData && <StudyDetail studyData={selectedStudyData} />}
      {selectedSerieData && <SerieDetail serieData={selectedSerieData} />}
    </div>
  );
};

DicomDetail.propTypes = {
  selectedPatientData: PropTypes.object,
  selectedStudyData: PropTypes.object,
  selectedSerieData: PropTypes.object
};

export default DicomDetail;
