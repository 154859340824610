import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal, message } from "antd";
import DicomDetail from "./DicomDetail";
import DicomDirectoryTree from "./DicomDirectoryTree";
import BaseButton from "../../common/baseComponents/BaseButton";
import { buttonConsts } from "../../common/baseComponents/baseComponentTypes";
import BaseCheckBox from "../../common/baseComponents/BaseCheckBox";

import "./DicomTree.less";

export const DicomTree = ({
  isVisible,
  onDismiss,
  isSerieSelected,
  onSeriesSelected,
  initSegmentation,
  path,
  patient,
  study,
  series,
  patientsData,
  selectedDicomeFile,
  closeTreeModal,
  changeSelectedItem,
  selectedPatientData,
  selectedStudyData,
  selectedSerieData
}) => {
  const [anonymizeInfo, setAnonymizeInfo] = useState(false);
  useEffect(() => {
    if (
      Object.entries(patientsData).length === 0 &&
      patientsData.constructor === Object &&
      selectedDicomeFile !== null
    ) {
      message.error("Sorry! The file is empty. Try with another one.");
      closeTreeModal();
    }
  }, [patientsData, selectedDicomeFile, closeTreeModal]);
  useEffect(() => {
    !isVisible && setAnonymizeInfo(false);
  }, [isVisible]);
  const createNewCase = () => {
    initSegmentation(path, patient, study, series, anonymizeInfo);
    onSeriesSelected();
  };
  const handleCheckboxChange = event => {
    setAnonymizeInfo(event.target.checked);
  };
  if (
    isVisible &&
    Object.entries(patientsData).length === 0 &&
    patientsData.constructor === Object
  ) {
    return null;
  } else {
    return (
      <div
        tabIndex="0"
        onKeyDown={e => {
          if (e.key === "Enter" && isSerieSelected) {
            createNewCase();
          }
        }}
      >
        <Modal
          centered
          className="dicom-tree-modal"
          title="Choose the case image series"
          visible={isVisible}
          width="75%"
          onCancel={onDismiss}
          maskClosable={false}
          footer={
            <div>
              <BaseButton
                config={{
                  type: buttonConsts.TYPE.LINK,
                  size: buttonConsts.SIZE.SMALL
                }}
                onClick={onDismiss}
                text="Cancel"
              />
              <BaseButton
                config={{
                  type: buttonConsts.TYPE.PRIMARY,
                  size: buttonConsts.SIZE.SMALL,
                  disabled: !isSerieSelected
                }}
                onClick={() => createNewCase()}
                text="Create new case"
              />
            </div>
          }
        >
          <div className="tree-layout">
            <div className="tree-section">
              {isVisible && (
                <DicomDirectoryTree
                  patientsData={patientsData}
                  changeSelectedItem={changeSelectedItem}
                />
              )}
            </div>
            <div className="detail-section">
              {isVisible && (
                <React.Fragment>
                  <DicomDetail
                    selectedPatientData={selectedPatientData}
                    selectedStudyData={selectedStudyData}
                    selectedSerieData={selectedSerieData}
                  />
                  <div className="anonymize-checkbox">
                    <BaseCheckBox
                      config={{
                        label: "Anonymize information during case creation",
                        checked: anonymizeInfo || false
                      }}
                      onChange={handleCheckboxChange}
                    />
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
        </Modal>
      </div>
    );
  }
};
DicomTree.propTypes = {
  isVisible: PropTypes.bool,
  isSerieSelected: PropTypes.bool,
  onDismiss: PropTypes.func,
  onSeriesSelected: PropTypes.func,
  initSegmentation: PropTypes.func,
  changeSelectedItem: PropTypes.func,
  path: PropTypes.string,
  patient: PropTypes.string,
  study: PropTypes.string,
  series: PropTypes.string,
  selectedPatientData: PropTypes.object,
  selectedSerieData: PropTypes.object,
  patientsData: PropTypes.object,
  selectedDicomeFile: PropTypes.string,
  closeTreeModal: PropTypes.func,
  selectedStudyData: PropTypes.object
};
export default DicomTree;
