import React from "react";
import PropTypes from "prop-types";
import { Radio } from "antd";
import "./BaseRadio.less";

const BaseRadio = ({ config = {}, onChange = null }) => {
  const options = config?.options ? config.options : [];
  const optionComponents = options.map(option => {
    return (
      <Radio key={option.value} disabled={option.disabled} value={option.value}>
        {option.label}
      </Radio>
    );
  });

  const onChangeBaseRadio = event => {
    onChange(event.target.value);
  };

  return (
    <Radio.Group
      buttonStyle={config ? config.buttonStyle : null}
      disabled={config ? config.disabled : null}
      onChange={onChangeBaseRadio}
      value={config ? config.value : ""}
      defaultValue={config ? config.defaultValue : ""}
    >
      {optionComponents}
    </Radio.Group>
  );
};

BaseRadio.propTypes = {
  config: PropTypes.object,
  onChange: PropTypes.func
};

export default BaseRadio;
