import { capitalize } from "lodash";

export const SOCKET_RUNNING = "RUNNING";
export const SOCKET_FINISHED = "FINISHED";
export const SOCKET_ERROR = "ERROR";
export const SOCKET_UPDATE = "update";

export const TODAY = "Today";

export const newCaseState = {
  UNPLANNED: "UNPLANNED",
  PLANNED: "PLANNED",
  DECLINED: "DECLINED"
};

export const newCaseFilter = {
  SURGERYDAY: "SURGERYDAY",
  POSTOP: "POSTOP"
};

export const requestStatus = {
  LOADING: "LOADING",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR"
};

export const genders = {
  MALE: "Male",
  FEMALE: "Female",
  ANONYMOUS: "Anonymous",
  UNSPECIFIED: "Unspecified"
};

export const surgeryMeasurementsState = {
  UNCHECKED: "UNCHECKED",
  ACCEPTED: "ACCEPTED",
  CORRECTED: "CORRECTED",
  REJECTED: "REJECTED"
};

export const parsedGenderValues = {
  MALE: "MALE",
  FEMALE: "FEMALE",
  ANONYMOUS: "ANONYMOUS",
  UNSPECIFIED: "UNSPECIFIED"
};

export const parsedOperationSideValues = {
  RIGHT: "RIGHT",
  LEFT: "LEFT"
};

export const latMedValues = {
  LATERALIZATION: "Lateralization",
  MEDIALIZATION: "Medialization"
};

export const postAntValues = {
  POSTERIOR: "Posterior",
  ANTERIOR: "Anterior"
};

export const HUMERAL_PATIENT_ANATOMY_LABELS = {
  MEASUREMENTS: "Measurements",
  HEAD_CUT_PLANE_ROTATION: "Head cut plane rotation",
  INCLINATION: "Inclination",
  HUMERAL_HEAD_DIAMETER: "Humeral head diameter"
};

export const HUMERAL_SURGERY_PROPERTIES_LABELS = {
  HEAD_CUT_DIAMETER: "Head cut diameter",
  HEAD_CUT_PLANE_INCLINATION: "Head cut plane inclination",
  HEAD_CUT_PLANE_ROTATION: "Head cut plane rotation",
  HEAD_ROTATION: "Head rotation"
};

export const dayNames = {
  0: "Sun",
  1: "Mon",
  2: "Tue",
  3: "Wed",
  4: "Thu",
  5: "Fri",
  6: "Sat"
};
export const MonthNames = {
  0: "Jan",
  1: "Feb",
  2: "Mar",
  3: "Apr",
  4: "May",
  5: "Jun",
  6: "Jul",
  7: "Aug",
  8: "Sep",
  9: "Oct",
  10: "Nov",
  11: "Dec"
};

export const arMonthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
];

export const casePlanningSaveStatus = {
  STATUS_UPDATED: "Status updated",
  CHANGES_SAVED: "Changes saved",
  CHANGES_SAVED_STATUS_UPDATED: "Changes saved and status updated"
};

export const casePlanningSaveStatusOffline = {
  STATUS_UPDATED:
    "Status updated. It will be synced when you have Internet connection.",
  CHANGES_SAVED:
    "Changes saved. They will be synced when you have Internet connection.",
  CHANGES_SAVED_STATUS_UPDATED:
    "Changes saved and status updated. They will be synced when you have Internet connection."
};

export const implantTypeConst = {
  Anatomic: {
    0: "Inset 0°",
    1: "Inset 5°",
    2: "Inset 10°"
  }
};

export const passwordErrorPlace = {
  CURRENT_PASSWORD: "CURRENT_PASSWORD",
  NEW_PASSWORD: "NEW_PASSWORD",
  REPEAT_PASSWORD: "REPEAT_PASSWORD"
};

export const reportGenerationStatus = {
  LOADING: "LOADING",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
  FILENAME_ERROR: "FILENAME_ERROR"
};

export const zoomType = {
  IN: "IN",
  OUT: "OUT"
};

export const ctScanType = {
  AXIAL: "AXIAL",
  CORONAL: "CORONAL",
  SAGITTAL: "SAGITTAL"
};

export const updateManager = {
  DOWNLOADING_UPDATE: "Downloading update ...",
  STARTING: "Starting ...",
  RESTART_APP: "Restart the app to install the update",
  DOWNLOADING_COMPLETE: "Download completed"
};

export const FILE_UPLOAD_STATUS = {
  UPLOADED_APP: "UPLOADED_APPLICATION",
  UPLOAD_IN_PROGRESS: "UPLOAD_IN_PROGRESS",
  UPLOADED_MANUAL: "UPLOADED_MANUAL"
};

export const CASE_SYNC_TOOLTIP = {
  LOADING: "Synchronizing",
  SUCCESS: "Case synced",
  NOT_SYNCED: "Sync case",
  ERROR: "Retry case sync"
};

export const SEGMENTATION_STATE = {
  FAILED: "FAILED",
  SUCCESSFUL: "SUCCESSFUL"
};

export const QUICK_VIEWS = ["Front", "Back", "Bottom", "Top", "Left", "Right"];

export const DEFAULT_PREFERENCES_OPTIONS = {
  measurements: {
    Planes: true,
    Scapula: true,
    "Coordinate axes": true,
    Humerus: false
  },
  planning: {
    Glenosphere: false,
    "Central screw": false,
    Implant: true,
    "Backside support": false,
    Pocket: false,
    "Rotation control": true,
    "Movement control": true,
    "Coordinate axes": false,
    Planes: false,
    Scapula: true,
    Humerus: false
  }
};

export const DEFAULT_MEASUREMENTS_OPTIONS = {
  opacity: 3,
  zoom: 0,
  cameraView: 0
};

export const DEFAULT_PLANNING_OPTIONS = {
  opacity: 3,
  zoom: 0,
  cameraView: 0
};

export const USER_GENERAL_SETTINGS_TYPES = {
  archive: "autoArchiveCases",
  darkMode: "planningDarkMode",
  download: "autoDownloadCases",
  upload: "autoUploadCases"
};

export const GENERAL_SETTINGS_MESSAGES = {
  generalTitle:
    "Manage your general app preferences. These configurations are applied only on the current computer.",
  uploadMessage:
    "With this option, choose to automatically push all case data to the cloud so you can plan anywhere.",
  downloadMessage:
    "With this option, choose to automatically download any new case from the cloud so you can plan anywhere.",
  planningMessage:
    "With this option, adjust the 3D planning scene to dark background theme and lighting for higher contrast with the bone surface. This option can be changed anytime, including during planning, from this menu.",
  subTitle: "case information automatically",
  noteMessageUpload:
    "Note: This option will use more upload data, about 100MB per case.",
  noteMessageDownload:
    "Note: This option will use more download data, about 100MB per case.",
  archivedMessage:
    "With this option, choose to automatically archive cases stored on this computer, which reduces hard drive space. This option removes 3D planning files but keeps case summary and plan report. The full case can still be downloaded from the cloud as desired."
};

export const PROFILE_KEYS = {
  profile: 0,
  preferences: 1,
  settings: 2
};

export const SEGMENTATION_DISCONNECTED =
  "Cannot connect to segmentation process.";

export const REQUEST_SUPPORT_MODAL_CLASS_NAMES = {
  oneCase: " one-case",
  twoCases: " two-cases",
  threeCases: " three-cases"
};

export const REQUEST_SUPPORT_MODAL_SUCCESS =
  "You will be notified when the segmentation is available.";

export const REQUEST_SUPPORT_MODAL_FAILED = "An error occurred. Try again.";

export const GLENOSPHERE_TYPE = {
  STANDARD: 0,
  ECCENTRIC: 1
};

export const SEGMENTATION_COLLAPSE_CLASSNAMES = {
  0: " inactive",
  1: " active"
};

export const DICOM_UPLOAD_LOGOUT_TITLE =
  "Are you sure you want to log out before finishing the request support?";

export const DICOM_UPLOAD_EXIT_TITLE =
  "Are you sure you want to exit before finishing the request support?";

export const DICOM_UPLOAD_LOGOUT_DESCRIPTION =
  "If you finish the session and the request support has not ended then the files will not send and our sales representative will put in contact with you.";

export const DICOM_AUTO_RETRY = {
  delay: 3000,
  condition: retries => retries < 2
};

export const NO_SERIES_TO_SEGMENT = "No series are being segmented";

export const LIST_DEFAULT_VALUE = "-";
export const DEFAULT_START_INDEX = 0;

export const REQUEST_STATUS = {
  LOADING: "LOADING",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR"
};

export const INACTIVITY_REFRESH = {
  REFRESH_TOKEN_FORCE_PERCENTAGE: 0.9
};

export const BO_AUTH_COOKIE_NAME = "SI_BACKOFFICE";

export const HISTORY_ACTION = {
  POP: "POP"
};

export const UNKNOWN = "Unknown";

export const KEYCLOAK_REQUIRED_ACTIONS = {
  UPDATE_PASSWORD: "UPDATE_PASSWORD",
  VERIFY_EMAIL: "VERIFY_EMAIL"
};

export const USER_ACTION_RELATED_MESSAGE = {
  LOADING: "Saving changes",
  SUCCESS: "Changes saved successfully.",
  ERROR: "Changes cannot be saved. Please, try again."
};

export const RESET_PASSWORD_MESSAGE = {
  LOADING: "Resetting password",
  SUCCESS: "Password reset successfully.",
  ERROR: "Password cannot be reset. Please, try again."
};

export const VERIFY_EMAIL = "VERIFY_EMAIL";

export const USER_ROLES = {
  SURGEON: "surgeon",
  SUPPORT_REQUEST_REVIEWER: "support_request_reviewer"
};

export const KEYCLOAK_USER_ROLES = {
  BACKOFFICE: "backoffice",
  SUPPORT_ENGINEER: "support_engineer",
  SALES_REP: "sales_rep"
};

export const USER_STATUS = {
  ENABLED: "Enabled",
  DISABLED: "Disabled"
};

export const SORT_DIRECTIONS = {
  ASCEND: "ascend",
  DESCEND: "descend"
};

export const SUPPORT_STATUS = {
  TEXT: {
    DRAFT: "New",
    INPROGRESS: "In progress",
    DONE: "Done"
  },
  VALUE: {
    DRAFT: "draft",
    INPROGRESS: "inprogress",
    DONE: "done"
  }
};

export const ASCII_ENCODE = {
  PERCENT: "%25",
  PIPE: "%7C",
  TILDE: "%7E"
};

export const ALIASES = {
  "application/x-zip-compressed": "application/zip"
};

export const inputType = {
  CHECKBOX: "checkbox",
  DATEPICKER: "datepicker",
  TEXTINPUT: "textinput",
  TEXTAREA: "textarea",
  RADIO: "radiobutton",
  SELECT: "select"
};

export const CASE_TASKS_PIPELINES = {
  CLONE: "inject-clone-case-segmentation",
  INJECT: "inject-new-case",
  UPDATE: "inject-update-case"
};

export const PAGINATION_SIZE = 10;
export const FIRST_PAGE = 1;

export const PORTAL_LOCATION_PATH = {
  SURGERY_CASES: "/surgery-cases",
  CASE_INVITATION: "/case-invitation"
};

export const BO_LOCATION_PATH = {
  ACCESS_DENIED: "/accessDenied",
  ADD_USER: "/users/add",
  ANALYTICS: "/analytics",
  SURGERY_CASES: "/surgery-cases",
  CASE_LIST: "/caseList",
  CASE_TASK: "/casetask",
  FINISH_CASE_TASK: "/casetask/finish",
  REQUEST_SUPPORT: "/requestsupport",
  REQUEST_SUPPORT_CASES: "/requestSupportCases",
  ROOT: "/",
  SURGEONS: "/surgeons",
  USERS: "/users",
  NEWS: "/news",
  NEWS_EDIT: "/news/edit",
  NEWS_ADD: "/news/add",
  NOTIFICATIONS: "/notifications",
  NOTIFICATIONS_EDIT: "/notifications/edit",
  NOTIFICATIONS_ADD: "/notifications/add"
};

export const TASK_TYPE = {
  INJECT: "Inject case",
  UPDATE: "Update case"
};

export const FORM_ITEM_LAYOUT = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 5 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 }
  }
};

export const CASE_TICKET_ATTR = {
  ID: "id",
  CREATED_DATE: "createdDate",
  STATE: "state",
  CASE_ID: "caseId",
  CASE_NUMBER: "caseNumber",
  USER_ID: "userId",
  REASON: "reason",
  PATIENT_NAME: "patientName"
};

export const NEWS_ATTR = {
  ID: "id",
  TITLE: "title",
  DESCRIPTION: "description",
  VALIDITY_PERIOD: "validityPeriod",
  BODY: "body"
};

export const NOTIFICATIONS_ATTR = {
  ID: "id",
  RELEASE_DATE: "releaseDate",
  VERSION: "version",
  CHANGE_LOG: "changeLog"
};

export const SURGERY_CASE_ATTR = {
  ID: "id",
  PATIENT_NAME: "patientName",
  SURGEON_ID: "userId",
  SURGEON_NAME: "surgeonName",
  MODALITY: "modality",
  MRN: "medicalRecordNumber",
  SURGERY_DATE: "surgeryDate",
  SURGERY_STATUS: "state",
  COMMENT: "comment",
  LAST_MODIFIED: "lastModified",
  CASE_NUMBER: "caseNumber",
  ORIGINAL_CASE_NUMBER: "originalCaseNumber",
  CLASSIFICATION: "classification",
  IS_DECLINED: "isDeclined",
  HAS_SEGMENTATION_DATA: "hasSegmentationData",
  FILES: "surgeryCaseFiles",
  IS_LAST_FILE_DOWNLOADED: "lastFileDownloaded",
  IS_ARCHIVE_DISABLED: "archiveDisabled",
  RELATIONSHIP: "relationship",
  CASE_STATUS: "caseStatus",
  IS_SYNCED: "isSynced"
};

export const CASE_TASKS = {
  CLONE: "Clone Case",
  INJECT: {
    ENGINEER: "Inject to Engineer",
    VERIFIER: "Inject to Verifier",
    USER: "Inject to User"
  },
  UPDATE: { USER: "Update Case" }
};

export const PIPELINE_COMMON_PARAM = {
  ADMIN_USERNAME: "ADMIN_USERNAME",
  ADMIN_PASSWORD: "ADMIN_PASSWORD",
  TARGET_USER: "TARGET_USER",
  IMPERSONATE: "IMPERSONATE",
  ISSUE_ID: "ISSUE_ID"
};

export const INJECT_NEW_CASE_PARAM = {
  ...PIPELINE_COMMON_PARAM,
  "application/json": "new-case.json",
  "application/zip": "support-segmentation-file.zip"
};

export const UPDATE_CASE_PARAM = {
  ...PIPELINE_COMMON_PARAM,
  "application/zip": "support-segmentation-file.zip",
  CASE_ID: "CASE_ID",
  RESET_PLAN_STATUS: "RESET_PLAN_STATUS"
};

export const resetPlanOptions = [
  {
    value: "--reset-plan-status",
    name: "YES"
  },
  {
    value: "--no-reset-plan-status",
    name: "NO"
  }
];

export const CLONE_CASE_PARAM = {
  ...PIPELINE_COMMON_PARAM,
  CASE_ID: "CASE_ID",
  REPLACE_COORDINATE_SYSTEM: "REPLACE_COORDINATE_SYSTEM",
  VOLUME: "VOLUME"
};

export const IMPERSONATE_USER = {
  YES: "--impersonate",
  NO: "-no-impersonate"
};

export const REPLACE_COORDINATE_SYSTEM = {
  YES: "--replace-coordinate-system",
  NO: "--no-replace-coordinate-system"
};

export const TICKET_FILES_CONFIG = {
  MAX_FILES: 3,
  STATUSES: {
    REMOVED: "removed"
  }
};

export const surgeryStatusFilters = [
  {
    text: capitalize(newCaseState.PLANNED),
    value: newCaseState.PLANNED
  },
  {
    text: capitalize(newCaseState.UNPLANNED),
    value: newCaseState.UNPLANNED
  }
];

export const caseStatusFilters = [
  {
    text: "In Process",
    value: "IN_PROCESS"
  },
  {
    text: "Ready",
    value: "READY"
  },
  {
    text: "In Review",
    value: "IN_REVIEW"
  },
  {
    text: "Contact Support",
    value: "CONTACT_SUPPORT"
  },
  {
    text: "Support Requested",
    value: "SUPPORT_REQUESTED"
  },
  {
    text: "Rejected",
    value: "REJECTED"
  },
  {
    text: "Archived",
    value: "ARCHIVED"
  }
];

export const SERIES_MODALITY = {
  CT: "CT",
  MR: "MR"
};

export const modalityFilters = [
  { text: SERIES_MODALITY.CT, value: SERIES_MODALITY.CT },
  { text: SERIES_MODALITY.MR, value: SERIES_MODALITY.MR }
];

export const caseFilterType = {
  patientName: "patient.fullName",
  medicalRecordNumber: "medicalRecordNumber",
  classification: "classification",
  caseNumber: "caseNumber"
};

export const CASE_STATUS = {
  IN_PROCESS: "IN_PROCESS",
  READY: "READY",
  IN_REVIEW: "IN_REVIEW",
  CONTACT_SUPPORT: "CONTACT_SUPPORT",
  SUPPORT_REQUESTED: "SUPPORT_REQUESTED",
  REJECTED: "REJECTED",
  ARCHIVED: "ARCHIVED"
};

export const TICKET_STATUS = {
  DRAFT: "draft",
  INPROGRESS: "inprogress",
  DONE: "done",
  REJECTED: "rejected"
};

export const caseStatusInfo = {
  IN_PROCESS: {
    description: "Case creation has been initiated."
  },
  READY: {
    description: {
      normal: "Now you can access to the 3D Planning.",
      support:
        "Our team has completed the request. Your case is now ready for planning."
    }
  },
  IN_REVIEW: {
    description:
      "This case is being reviewed by our support team. Soon your case will be updated. For any comments, please use the support request: JIRA_TICKET_ID."
  },
  CONTACT_SUPPORT: {
    description: {
      normal:
        "There was an issue processing this case. To assist you further, please create a support request.",
      info_needed:
        "There was an issue processing your case. Our team requires more information, please contact us by request JIRA_TICKET_ID."
    }
  },
  SUPPORT_REQUESTED: {
    description:
      "Our team has been notified. For more information go to the support request JIRA_TICKET_ID."
  },
  REJECTED: {
    description:
      "Your case couldn’t be processed. Contact our support team for any doubts or questions by request JIRA_TICKET_ID. We look forward to assisting you further in this matter."
  },
  ARCHIVED: {
    description:
      "The case needs to be synced in order to gain access to the planning."
  }
};

export const COMMERCIAL_AVAILABILITY = {
  0: "Available",
  1: "Special order item",
  2: "Not commercially available",
  3: "Hidden"
};

export const REVERSE_CATEGORY = {
  BASEPLATES: "baseplates",
  GLENOSPHERES: "glenospheres",
  CENTRAL_SCREWS: "centralScrews"
};

export const REVERSE_CATEGORY_CODE = {
  [REVERSE_CATEGORY.BASEPLATES]: "ImplantCode",
  [REVERSE_CATEGORY.GLENOSPHERES]: "GlenosphereCode",
  [REVERSE_CATEGORY.CENTRAL_SCREWS]: "CentralScrewCode"
};

export const HUMERAL_REVERSE_CATEGORY = {
  STEMS: "stems",
  TRAYS: "trays",
  BEARINGS: "bearings"
};

export const HUMERAL_REVERSE_CATEGORY_CODE = {
  [HUMERAL_REVERSE_CATEGORY.STEMS]: "HumeralStemCode",
  [HUMERAL_REVERSE_CATEGORY.TRAYS]: "HumeralReverseTrayCode",
  [HUMERAL_REVERSE_CATEGORY.BEARINGS]: "HumeralReverseBearingCode"
};

export const HUMERAL_ANATOMIC_CATEGORY = {
  STEMS: "stems",
  ECCENTRIC_HEADS: "eccentric_heads"
};

export const HUMERAL_ANATOMIC_CATEGORY_CODE = {
  [HUMERAL_ANATOMIC_CATEGORY.STEMS]: "HumeralStemCode",
  [HUMERAL_ANATOMIC_CATEGORY.ECCENTRIC_HEADS]: "HumeralAnatomicHeadCode"
};

export const EMPTY_DATA = "-";

export const LOADING_ERROR = "Sorry, could not get planning information.";
