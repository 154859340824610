import React from "react";
import PropTypes from "prop-types";

import "./QualityTag.less";

const QualityTag = ({ content }) => {
  return (
    <div className={`quality-content ${content.toLowerCase().split(" ")[0]}`}>
      {content}
    </div>
  );
};

QualityTag.propTypes = {
  content: PropTypes.string
};

export default QualityTag;
