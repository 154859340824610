const URL_SEPARATOR = `/`;
const URL_WITH_PARAM_DELIMITER = `?`;
const PARAM_SEPARATOR = `&`;
const PARAM_VALUE = `=`;
const SORT_BY_DIRECTION_DELIMITER = `:`;

export const sortByFactory = param => {
  return `${param.param}${SORT_BY_DIRECTION_DELIMITER}${param.value}`;
};

export const paramFactory = params => {
  let paramStrings = params.map(param => {
    return `${param.param}${PARAM_VALUE}${param.value}`;
  });
  return paramStrings.join(PARAM_SEPARATOR);
};

export const pathFactory = (...parts) => {
  return parts.join(URL_SEPARATOR).replace(/([^:]\/)\/+/g, "$1");
};

export const urlWithParamsFactory = (url, paramsString) => {
  return `${url}${URL_WITH_PARAM_DELIMITER}${paramsString}`;
};
