import React from "react";
import PropTypes from "prop-types";
import RowDetail from "./RowDetail";

const StudyDetail = ({ studyData }) => {
  const {
    id,
    studyDate,
    perfPhysicianName,
    description,
    accessionNumber,
    studyTime
  } = studyData;
  return (
    <div className="detail-layout info-wrapper">
      <div className="title full-row-item">Study</div>
      <RowDetail label="ID" value={id} />
      <RowDetail label="Accession n#" value={accessionNumber} />
      <RowDetail label="Study date" value={studyDate} />
      <RowDetail label="Study time" value={studyTime} />
      <div className="full-row-item">
        <RowDetail label="Perf. physician name" value={perfPhysicianName} />
      </div>
      <div className="full-row-item">
        <RowDetail label="Description" value={description} />
      </div>
    </div>
  );
};

StudyDetail.propTypes = {
  studyData: PropTypes.object
};

export default StudyDetail;
