import React from "react";
import PropTypes from "prop-types";
import { compact } from "lodash";
import { CloseCircleFilled } from "@ant-design/icons";
import PlanningResult from "./PlanningResult";
import {
  parseRotation,
  parseGlenosphereType,
  getBasePlateStem,
  getBasePlateDiameter,
  getBasePlateAngle,
  getGlenosphereDiameter,
  getGlenosphereLabel,
  getBaseplateThickness,
  getImplantDisclaimer,
  getHumeralImplant,
  getTrayImplant,
  getBearingImplant,
  getEccentricHeadImplant,
  getSelectedBaseplate,
  getSelectedCentralScrew,
  getSelectedGlenosphere,
  getTrayDiameter,
  getTrayOffset,
  getBearingType,
  getBearingDiameter,
  getBearingAngle,
  getBearingThickness,
  getHumeralImplantSize,
  getEccentricHeadDiameter,
  getEccentricHeadThickness,
  getScapularPropertiesValues,
  getHumeralPatientAnatomyValues,
  getHumeralSurgeryPropertiesValues,
  getScapularPatientAnatomyValues,
  getScapularImplantSupportValues,
  getStemName,
  getGlenosphereOffset,
  getGlenosphereRotation,
  getCentralScrewLength,
  getViewConstructGlenoidCorrectionValues,
  getViewConstructArmCorrectionValues,
  TAB_TYPES
} from "../../../helpers/launchReconHelper";
import { parseIsoStringToDate } from "../../../helpers/datesHelper";
import {
  REVERSE_CATEGORY,
  REVERSE_CATEGORY_CODE,
  HUMERAL_REVERSE_CATEGORY,
  HUMERAL_REVERSE_CATEGORY_CODE,
  HUMERAL_ANATOMIC_CATEGORY_CODE,
  HUMERAL_ANATOMIC_CATEGORY,
  LOADING_ERROR,
  GLENOSPHERE_TYPE
} from "../../../helpers/consts";
import "./PlanningInfoTab.less";

const getHumeralImplantPropertiesSection = (
  surgeryPlanning,
  casesWithSpecialAttention,
  implantsInfo,
  isReverse
) => {
  const humeralImplantData = surgeryPlanning.HumeralImplantData;
  const implantCode = humeralImplantData.HumeralStemCode;

  if (isReverse) {
    const selectedHumeralReverseImplant = getHumeralImplant(
      implantCode,
      implantsInfo
    );
    const selectedTrayImplant = getTrayImplant(
      humeralImplantData?.HumeralReverseTrayCode,
      implantsInfo
    );
    const selectedBearingImplant = getBearingImplant(
      humeralImplantData?.HumeralReverseBearingCode,
      implantsInfo
    );

    return (
      <React.Fragment>
        <PlanningResult
          planningResultInfo={[
            {
              title: "Implant properties",
              values: compact([
                {
                  value: getStemName(
                    implantsInfo,
                    selectedHumeralReverseImplant?.stemType
                  ),
                  attribute: "Type",
                  disclaimer: getImplantDisclaimer(
                    casesWithSpecialAttention,
                    humeralImplantData,
                    HUMERAL_REVERSE_CATEGORY_CODE[
                      HUMERAL_REVERSE_CATEGORY.STEMS
                    ]
                  )
                },
                {
                  value: getHumeralImplantSize(selectedHumeralReverseImplant),
                  attribute: "Size",
                  basisType: "secondary"
                }
              ])
            }
          ]}
        />
        <PlanningResult
          planningResultInfo={[
            {
              title: "Tray",
              disclaimer: getImplantDisclaimer(
                casesWithSpecialAttention,
                humeralImplantData,
                HUMERAL_REVERSE_CATEGORY_CODE[HUMERAL_REVERSE_CATEGORY.TRAYS]
              ),
              values: compact([
                {
                  value: getTrayDiameter(selectedTrayImplant, implantsInfo),
                  attribute: "Diameter"
                },
                {
                  value: getTrayOffset(selectedTrayImplant, implantsInfo),
                  attribute: "Offset",
                  basisType: "secondary"
                }
              ])
            }
          ]}
        />
        <PlanningResult
          hasSeparator
          planningResultInfo={[
            {
              title: "Bearing",
              disclaimer: getImplantDisclaimer(
                casesWithSpecialAttention,
                humeralImplantData,
                HUMERAL_REVERSE_CATEGORY_CODE[HUMERAL_REVERSE_CATEGORY.BEARINGS]
              ),
              values: compact([
                {
                  value: getBearingType(selectedBearingImplant),
                  attribute: "Type"
                },
                {
                  value: getBearingDiameter(
                    selectedBearingImplant,
                    implantsInfo
                  ),
                  attribute: "Diameter"
                },
                selectedBearingImplant?.typeCode
                  ? {
                      value: getBearingAngle(
                        selectedBearingImplant,
                        implantsInfo
                      ),
                      attribute: "Angle"
                    }
                  : {
                      value: getBearingThickness(
                        selectedBearingImplant,
                        implantsInfo
                      ),
                      attribute: "Thickness"
                    }
              ])
            }
          ]}
        />
      </React.Fragment>
    );
  } else {
    const selectedHumeralAnatomicImplant = getHumeralImplant(
      implantCode,
      implantsInfo
    );
    const selectedEccentricHeadImplant = getEccentricHeadImplant(
      humeralImplantData?.HumeralAnatomicHeadCode,
      implantsInfo
    );

    return (
      <React.Fragment>
        <PlanningResult
          planningResultInfo={[
            {
              title: "Implant properties",
              values: compact([
                {
                  value: getStemName(
                    implantsInfo,
                    selectedHumeralAnatomicImplant?.stemType
                  ),
                  attribute: "Type",
                  disclaimer: getImplantDisclaimer(
                    casesWithSpecialAttention,
                    humeralImplantData,
                    HUMERAL_ANATOMIC_CATEGORY_CODE[
                      HUMERAL_ANATOMIC_CATEGORY.STEMS
                    ]
                  )
                },
                {
                  value: getHumeralImplantSize(selectedHumeralAnatomicImplant),
                  attribute: "Size",
                  basisType: "secondary"
                }
              ])
            }
          ]}
        />
        <PlanningResult
          hasSeparator
          planningResultInfo={[
            {
              values: compact([
                {
                  value: "Eccentric humeral head",
                  disclaimer: getImplantDisclaimer(
                    casesWithSpecialAttention,
                    humeralImplantData,
                    HUMERAL_ANATOMIC_CATEGORY_CODE[
                      HUMERAL_ANATOMIC_CATEGORY.ECCENTRIC_HEADS
                    ]
                  )
                },
                {
                  value: getEccentricHeadDiameter(
                    selectedEccentricHeadImplant,
                    implantsInfo
                  ),
                  attribute: "Diameter"
                },
                {
                  value: getEccentricHeadThickness(
                    selectedEccentricHeadImplant,
                    implantsInfo
                  ),
                  attribute: "Thickness"
                }
              ])
            }
          ]}
        />
      </React.Fragment>
    );
  }
};

const getScapularImplantPropertiesSection = (
  implantData,
  isReverse,
  implantsInfo,
  casesWithSpecialAttention,
  operationSideValue,
  surgeryPlanning
) => {
  if (isReverse) {
    const reverseData = implantData.ReverseOptsData;

    const selectedBaseplate = getSelectedBaseplate(
      implantData.ImplantCode,
      implantsInfo
    );

    const selectedCentralScrew = getSelectedCentralScrew(
      implantData.CentralScrewCode,
      implantsInfo
    );

    const selectedGlenosphere = getSelectedGlenosphere(
      implantData.GlenosphereCode,
      implantsInfo
    );

    return (
      <React.Fragment>
        <PlanningResult
          planningResultInfo={[
            {
              title: "Baseplate properties",
              disclaimer: getImplantDisclaimer(
                casesWithSpecialAttention,
                implantData
              ),
              values: [
                {
                  value: getBasePlateDiameter(selectedBaseplate, implantsInfo),
                  attribute: "Diameter"
                },
                {
                  value: getBasePlateStem(selectedBaseplate, implantsInfo),
                  attribute: "Stem"
                },
                {
                  value: getBasePlateAngle(selectedBaseplate, implantsInfo),
                  attribute: "Angle",
                  basisType:
                    reverseData.BaseplateStem === 0 ? "default" : "secondary"
                },
                reverseData.BaseplateStem === 0 && {
                  value: getBaseplateThickness(selectedBaseplate, implantsInfo),
                  attribute: "Thickness"
                }
              ]
            }
          ]}
        />
        <PlanningResult
          noPadding
          planningResultInfo={[
            {
              title: "6.0mm central screw",
              disclaimer: getImplantDisclaimer(
                casesWithSpecialAttention,
                implantData,
                REVERSE_CATEGORY_CODE[REVERSE_CATEGORY.CENTRAL_SCREWS]
              ),
              values: [
                {
                  value: getCentralScrewLength(
                    selectedCentralScrew,
                    implantsInfo
                  ),
                  attribute: "Length"
                }
              ]
            }
          ]}
        />
        <PlanningResult
          hasSeparator
          noPadding
          planningResultInfo={[
            {
              title: "Glenosphere",
              disclaimer: getImplantDisclaimer(
                casesWithSpecialAttention,
                implantData,
                REVERSE_CATEGORY_CODE[REVERSE_CATEGORY.GLENOSPHERES]
              ),
              values: [
                {
                  value: parseGlenosphereType(selectedGlenosphere),
                  attribute: "Type"
                },
                {
                  value: getGlenosphereDiameter(
                    selectedGlenosphere,
                    implantsInfo
                  ),
                  attribute: "Diameter"
                },
                {
                  value:
                    selectedGlenosphere?.type === GLENOSPHERE_TYPE.STANDARD
                      ? getGlenosphereOffset(selectedGlenosphere, implantsInfo)
                      : getGlenosphereRotation(
                          parseRotation(reverseData.GlenosphereRotation)
                        ),
                  attribute: getGlenosphereLabel(reverseData.GlenosphereType)
                }
              ]
            }
          ]}
        />
        <PlanningResult
          hasSeparator
          planningResultInfo={[
            {
              title: "Surgery properties",
              values: getScapularPropertiesValues(
                1,
                implantsInfo,
                operationSideValue,
                surgeryPlanning
              )
            }
          ]}
        />
      </React.Fragment>
    );
  } else {
    return (
      <PlanningResult
        hasSeparator
        planningResultInfo={[
          {
            title: "Glenoid implant properties",
            disclaimer: getImplantDisclaimer(
              casesWithSpecialAttention,
              surgeryPlanning.ImplantData
            ),
            values: getScapularPropertiesValues(
              0,
              implantsInfo,
              operationSideValue,
              surgeryPlanning
            )
          }
        ]}
      />
    );
  }
};

const getScapulaTabValues = (
  surgeryPlanning,
  surgeryMeasurements,
  operationSideValue,
  isReverse,
  implantsInfo,
  casesWithSpecialAttention
) => {
  const patientProfileData = surgeryMeasurements.patientProfileData;
  const implantData = surgeryPlanning.ImplantData;
  return (
    <React.Fragment>
      <PlanningResult
        hasSeparator
        planningResultInfo={[
          {
            title: "Patient's anatomy",
            values: getScapularPatientAnatomyValues(
              surgeryPlanning,
              patientProfileData,
              operationSideValue
            )
          }
        ]}
      />
      {getScapularImplantPropertiesSection(
        implantData,
        isReverse,
        implantsInfo,
        casesWithSpecialAttention,
        operationSideValue,
        surgeryPlanning
      )}
      <PlanningResult
        planningResultInfo={[
          {
            title: "Implant support",
            values: getScapularImplantSupportValues(implantData)
          }
        ]}
      />
    </React.Fragment>
  );
};

const getHumeralTabValues = (
  surgeryPlanning,
  surgeryMeasurements,
  operationSideValue,
  casesWithSpecialAttention,
  implantsInfo,
  isReverse
) => {
  return (
    <React.Fragment>
      <PlanningResult
        hasSeparator
        planningResultInfo={[
          {
            title: "Patient's anatomy",
            values: getHumeralPatientAnatomyValues(
              surgeryPlanning,
              surgeryMeasurements,
              operationSideValue
            )
          }
        ]}
      />
      {getHumeralImplantPropertiesSection(
        surgeryPlanning,
        casesWithSpecialAttention,
        implantsInfo,
        isReverse
      )}
      <PlanningResult
        planningResultInfo={[
          {
            title: "Surgery properties",
            values: getHumeralSurgeryPropertiesValues(
              isReverse,
              surgeryPlanning,
              operationSideValue
            )
          }
        ]}
      />
    </React.Fragment>
  );
};

const getConstructTabValues = (surgeryPlanning, operationSideValue) => {
  return (
    <React.Fragment>
      <PlanningResult
        hasSeparator
        planningResultInfo={[
          {
            title: "Glenoid Correction",
            values: getViewConstructGlenoidCorrectionValues(
              surgeryPlanning.ImplantData,
              operationSideValue
            )
          }
        ]}
      />
      <PlanningResult
        planningResultInfo={[
          {
            title: "Arm Change",
            values: getViewConstructArmCorrectionValues(
              surgeryPlanning.ViewConstructData,
              operationSideValue
            )
          }
        ]}
      />
    </React.Fragment>
  );
};

const PlanningInfoTab = ({
  type,
  surgeryPlanning,
  surgeryMeasurements,
  operationSideValue,
  lastModifiedPlanningDate,
  casesWithSpecialAttention,
  implantsInfo
}) => {
  const isReverse = surgeryPlanning.Procedure;

  const getPlanningInfo = () => {
    try {
      switch (type) {
        case TAB_TYPES.SCAPULA:
          return getScapulaTabValues(
            surgeryPlanning,
            surgeryMeasurements,
            operationSideValue,
            isReverse,
            implantsInfo,
            casesWithSpecialAttention
          );
        case TAB_TYPES.HUMERUS:
          return getHumeralTabValues(
            surgeryPlanning,
            surgeryMeasurements,
            operationSideValue,
            casesWithSpecialAttention,
            implantsInfo,
            isReverse
          );
        case TAB_TYPES.CONSTRUCT:
          return getConstructTabValues(surgeryPlanning, operationSideValue);
        default:
          break;
      }
    } catch (error) {
      console.log(`While getting planning values: ${error}`);
      throw error;
    }
  };

  try {
    return (
      <React.Fragment>
        {getPlanningInfo()}
        {lastModifiedPlanningDate && (
          <div className="last-modified">
            {`Last modified 
          ${parseIsoStringToDate(lastModifiedPlanningDate)}`}
          </div>
        )}
      </React.Fragment>
    );
  } catch {
    return (
      <div className="planning-error-container">
        <CloseCircleFilled />
        {LOADING_ERROR}
      </div>
    );
  }
};

PlanningInfoTab.propTypes = {
  surgeryPlanning: PropTypes.object,
  surgeryMeasurements: PropTypes.object,
  casesWithSpecialAttention: PropTypes.object,
  operationSideValue: PropTypes.string,
  lastModifiedPlanningDate: PropTypes.string,
  implantsInfo: PropTypes.object,
  type: PropTypes.string
};

export default PlanningInfoTab;
