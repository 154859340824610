import {
  LIST_DEFAULT_VALUE,
  REQUEST_STATUS,
  INJECT_NEW_CASE_PARAM,
  ALIASES
} from "./consts";

export const hasErrors = fieldsError => {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
};

export const notAllFieldsAreTouched = (fields, isFieldTouched) => {
  return fields
    .map(fieldName => isFieldTouched(fieldName))
    .some(field => !field);
};

export const isCreateUserDisabled = (
  getFieldsError,
  isFieldTouched,
  createUserStatus,
  resetPasswordStatus,
  isEditing
) => {
  if (isEditing) {
    return (
      hasErrors(getFieldsError()) ||
      createUserStatus === REQUEST_STATUS.LOADING ||
      resetPasswordStatus === REQUEST_STATUS.LOADING
    );
  }
  return (
    hasErrors(getFieldsError()) ||
    notAllFieldsAreTouched(
      ["firstName", "lastName", "username", "email"],
      isFieldTouched
    ) ||
    createUserStatus === REQUEST_STATUS.LOADING
  );
};

export const showActionStatusMessage = (
  actionStatus,
  message,
  clearActionStatus,
  resetFields,
  errorMessage,
  messageKey,
  messageContent
) => {
  if (actionStatus) {
    message.destroy();
    const statuses = {
      [REQUEST_STATUS.LOADING]: () => {
        message.loading({
          content: messageContent.LOADING,
          key: messageKey.LOADING
        });
      },
      [REQUEST_STATUS.SUCCESS]: () => {
        message.success({
          content: messageContent.SUCCESS,
          key: messageKey.SUCCESS,
          duration: 4
        });
        clearActionStatus();
        resetFields && resetFields();
      },
      [REQUEST_STATUS.ERROR]: () => {
        message.error({
          content: errorMessage,
          key: messageKey.ERROR,
          duration: 4
        });
        clearActionStatus();
      }
    };
    statuses[actionStatus]();
  }
};

export const checkLoadingStatus = statuses => {
  return statuses.includes(REQUEST_STATUS.LOADING);
};

export const parseActionError = (error, dispatch, type, message) => {
  const errorMessage = error?.response?.data?.errorMessage;
  dispatch({
    type: type.ERROR,
    payload: errorMessage || message.ERROR
  });
};

export const sortFields = (a, b) => {
  return ("" + a).localeCompare(b);
};

export const filterByText = (value, record, filter) => {
  return ("" + record[filter])
    .toString()
    .toLowerCase()
    .includes(value.toLowerCase());
};

export const checkValue = value => {
  if (value === "") {
    return value || LIST_DEFAULT_VALUE;
  } else {
    return value ?? LIST_DEFAULT_VALUE;
  }
};

export const addNewCaseFilesToBody = (files, formData) => {
  files.fileList.forEach(file => {
    formData.append(
      INJECT_NEW_CASE_PARAM[file.type] ||
        INJECT_NEW_CASE_PARAM[ALIASES[file.type]],
      file.originFileObj
    );
  });
};
