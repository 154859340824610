import React from "react";
import PropTypes from "prop-types";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import RowDetail from "./RowDetail";
import QualityTag from "../QualityTag";
import { getQualityTag } from "../../../../helpers/caseHelper";
import "./PatientDetail.less";

const HIGH_TAG = "H";
const NOT_CLASSIFIED_TAG = "NC";

const getQualityInfo = (value, quality, seriesQuality) => {
  return (
    <div className="protocol-metadata">
      {value}
      {seriesQuality !== HIGH_TAG &&
        ![NOT_CLASSIFIED_TAG, HIGH_TAG].includes(quality) && (
          <Tooltip title="Not so good">
            <InfoCircleOutlined />
          </Tooltip>
        )}
    </div>
  );
};

const parsePatientOrientation = value => {
  return Number.isInteger(value) ? value : value.toFixed(2);
};

const SerieDetail = ({ serieData }) => {
  const {
    number,
    laterality,
    description,
    modality,
    constrastAgent,
    qualityTag,
    protocolValues
  } = serieData;
  const protocolMetadata = protocolValues.protocol_metadata;

  return (
    <div className="detail-layout info-wrapper">
      <div className="title full-row-item">
        Series
        <QualityTag content={getQualityTag(qualityTag)} />
      </div>
      <div className="full-row-item">
        <RowDetail label="Description" value={description} />
      </div>
      <RowDetail label="Number" value={number} />
      <RowDetail label="Modality" value={modality} />
      <RowDetail label="Laterality" value={laterality} />
      <RowDetail label="Constrast agent" value={constrastAgent} />
      <RowDetail
        label="Slice thickness"
        value={getQualityInfo(
          protocolMetadata.sliceThickness.value,
          protocolMetadata.sliceThickness.qualityTag,
          qualityTag
        )}
      />
      <RowDetail
        label="Amount of slices"
        value={getQualityInfo(
          protocolMetadata.amountOfSlices.value,
          protocolMetadata.amountOfSlices.qualityTag,
          qualityTag
        )}
      />
      <RowDetail
        label="Image size"
        value={getQualityInfo(
          protocolMetadata.imageSize.value,
          protocolMetadata.imageSize.qualityTag,
          qualityTag
        )}
      />
      <RowDetail
        label="Pixel spacing"
        value={getQualityInfo(
          protocolMetadata.pixelSpacing.value,
          protocolMetadata.pixelSpacing.qualityTag,
          qualityTag
        )}
      />
      <RowDetail
        label="FOV (Field of view)"
        value={getQualityInfo(
          protocolMetadata.fileOfView.value,
          protocolMetadata.fileOfView.qualityTag,
          qualityTag
        )}
      />
      <RowDetail
        label="Patient orientation"
        value={getQualityInfo(
          `${parsePatientOrientation(
            parseFloat(protocolMetadata.patientOrientation.x)
          )}°, ${parsePatientOrientation(
            parseFloat(protocolMetadata.patientOrientation.y)
          )}°`,
          protocolMetadata.patientOrientation.qualityTag,
          qualityTag
        )}
      />
      <RowDetail
        label="Exposure time"
        value={getQualityInfo(
          protocolMetadata.exposureTime.value,
          protocolMetadata.exposureTime.qualityTag,
          qualityTag
        )}
      />
      <RowDetail
        label="Convolution Kernel"
        value={getQualityInfo(
          protocolMetadata.convolutionKernel.value,
          protocolMetadata.convolutionKernel.qualityTag,
          qualityTag
        )}
      />
      <RowDetail
        label="KVP"
        value={getQualityInfo(
          protocolMetadata.kvp.value,
          protocolMetadata.kvp.qualityTag,
          qualityTag
        )}
      />
      <RowDetail
        label="Patient position"
        value={getQualityInfo(
          protocolMetadata.patientPosition.value,
          protocolMetadata.patientPosition.qualityTag,
          qualityTag
        )}
      />
      <div className="full-row-item">
        <RowDetail
          label="Transfer syntax"
          value={getQualityInfo(
            protocolMetadata.transferSyntaxUID.value,
            protocolMetadata.transferSyntaxUID.qualityTag,
            qualityTag
          )}
        />
      </div>
    </div>
  );
};

SerieDetail.propTypes = {
  serieData: PropTypes.object
};

export default SerieDetail;
